import React from 'react';
import { useParams, useNavigate } from 'react-router-dom'
import { useEffect, useState } from "react";
import axios from '../../lib/axios';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Moment from 'moment'; 
import Table from 'react-bootstrap/Table';
import EnteteAdmin from '../../components/componentAdmin/EnteteAdmin'
import Swal from 'sweetalert2'; 
import { useTranslation } from 'react-i18next';


const AdminAfficherDetailAvis = () => {
    const { slug, id } = useParams();
    const [avis, setAvis] = useState({});
    const [societe, setSociete] = useState({});
    const [pieceJointes, setPieceJointes] = useState([]);
    const [type, setType] = useState('avis');
    const navigate = useNavigate();
    const ud = JSON.parse(window.sessionStorage.getItem('userData'));
    const { t, i18n } = useTranslation();

    

    useEffect(() => {
        if(ud != null && ud.user.role_id ==1 ){ 
            getAvisDetail(); 
        }
        else{
            navigate('/login')
        }
    }, []);

    const getAvisDetail = () => {
        axios.get('/api/tic/davis/'+ id, {
            headers: {
                "Accept": "application/json"
            }
        }).then(response => {
            setAvis(response.data.avis); 
            setPieceJointes(response.data.pieceJointes); 
            setSociete(response.data.avis.societe);
            console.log(response.data)  
        }).catch(error => {
            console.log(error);
        });
    };

    var detailAvis  = {}

    i18n.language == 'fr' ? 
        detailAvis = {
        'id' : avis.id, 
        'libelle' : avis.libelle, 
        'contenue' : avis.contenue, 
        } 
        :  
        detailAvis = {
        'id' : avis.id,
        'libelle' : avis.libelle_ar, 
        'contenue' : avis.contenue_ar,
        }

    const validerAvis = () => { 
        Swal.fire({
            title: 'Valider cet Apple Offre ?',
            showDenyButton: true, 
            confirmButtonText: 'Yes',
            denyButtonText: 'No',
            customClass: {
                actions: 'my-actions',
                cancelButton: 'order-1 right-gap',
                confirmButton: 'order-2',
                denyButton: 'order-3',
            }
            }).then((result) => {
            if (result.isConfirmed) { 
                axios.post(`/api/validerAvis/${id}`, {
                headers: {
                    'Accept': 'application/json', 
                }
                }).then(response => {  
                Swal.fire(response.data.validation, '', 'success')  
                }).catch(error => {
                    console.log(error)    
                });
            } else if (result.isDenied) {
                Swal.fire('Validation annuler', '', 'info')
            }
        })  
     

    };

    const desactiverAvis = () => { 
        Swal.fire({
            title: 'Valider cet Apple Offre ?',
            showDenyButton: true, 
            confirmButtonText: 'Yes',
            denyButtonText: 'No',
            customClass: {
                actions: 'my-actions',
                cancelButton: 'order-1 right-gap',
                confirmButton: 'order-2',
                denyButton: 'order-3',
            }
            }).then((result) => {
            if (result.isConfirmed) { 
                axios.post(`/api/desactiverAvis/${id}`, {
                headers: {
                    'Accept': 'application/json', 
                }
                }).then(response => {  
                Swal.fire(response.data.validation, '', 'success')  
                }).catch(error => {
                    console.log(error)    
                });
            } else if (result.isDenied) {
                Swal.fire('Validation annuler', '', 'info')
            }
        })  
     

    };

    const annulerAvis = () => { 
        Swal.fire({
            title: 'Annuler cet Apple Offre ?',
            showDenyButton: true, 
            confirmButtonText: 'Yes',
            denyButtonText: 'No',
            customClass: {
                actions: 'my-actions',
                cancelButton: 'order-1 right-gap',
                confirmButton: 'order-2',
                denyButton: 'order-3',
            }
            }).then((result) => {
            if (result.isConfirmed) {

                axios.post(`api/tic/davis/annuler/${id}/${type}`, {
                headers: {
                    'Accept': 'application/json', 
                }
                }).then(response => {  
                Swal.fire(response.data.validation, '', 'success')
                navigate('/tic')  
                
                }).catch(error => {
                    console.log(error)    
                });

                
            } else if (result.isDenied) {
                Swal.fire('Validation annuler', '', 'info')
            }
        })  
     

    };

    const retournerAvis = () => { 
        Swal.fire({
            title: t('retourner_offre_emp'),
            showDenyButton: true, 
            confirmButtonText: t('oui'),
            denyButtonText: t('non'),
            customClass: {
                actions: 'my-actions',
                cancelButton: 'order-1 right-gap',
                confirmButton: 'order-2',
                denyButton: 'order-3',
            }
            }).then((result) => {
            if (result.isConfirmed) {

                axios.post(`api/tic/davis/retourne/${id}/${type}`, {
                headers: {
                    'Accept': 'application/json', 
                }
                }).then(response => { 
                console.log(response.data)
                Swal.fire(response.data.validation, '', 'success')
                navigate('/tic')
                //   Swal.fire({icon: "success", text: response.data.validation,})  
                
                }).catch(error => {
                    console.log(error)    
                });

                
            } else if (result.isDenied) {
                Swal.fire(t('Validation_annuler'), '', 'info')
            }
        })  
     

    };

    return (
        <>
            <EnteteAdmin></EnteteAdmin>   
            <div style={{padding:'30px'}}>  
                <Row> 
                    <Col xs="12" lg="12">
                        <div className='info-soci-emp' style={{paddingRight:'60px', padding:'2px'}}> 
                            <p className='fontAlmari'><FontAwesomeIcon icon="clock" color="#255025" size="xs" /> {t('limite')} :  {avis.fin}</p> 
                        </div>
                    </Col> 


                    <Col xs="12" lg="12">
                        <center> 
                            {societe.logo == null ? <img src="/images/offreRIMTIC.jpg" alt=""  style={{maxWidth:'200px'}}/> : 
                            <img className='image' src={'https://www.rimtic.com/storage/images/' + societe.logo} alt='Profile' style={{maxWidth:'200px'}} />
                            }
                            <p className='fontAlmari' style={{textAlign:'center',  fontWeight:'bold',marginTop:'1px' }}>{societe.nom}</p>
                        </center>
                    </Col>

                    <Col xs="12" lg="12" >
                        <p className='fontAlmari' style={{textAlign:'center', fontSize:'20px',margin:'10px' }}>{detailAvis.libelle != 'null' ? detailAvis.libelle : null}</p>
                    </Col> 

                    <Col xs="12" lg="12" >
                        <div className='fontAlmari' style={{padding:'15px 30px 15px 30px'}} dangerouslySetInnerHTML={{__html: detailAvis.contenue != '<p>null</p>' && detailAvis.contenue != 'null' ? detailAvis.contenue : null}} ></div>
                    </Col>


                    <Table striped bordered hover size="sm" style={{width:'70%', margin:'2px 0px 0px 40px'}}> 
                        <tbody>
                            {pieceJointes.map((o) => ( 
                            <tr>
                                <td><img src="https://www.rimtic.com/images/icons/docx.png" alt="" style={{width:'32px'}} /></td>
                                <td><a href={`https://www.rimtic.com/storage/documents/${o.nom}`} download target="_blank" style={{width:'98%'}}>{o.nom}</a></td> 
                            </tr> 
                            ))}
                            
                        </tbody>
                    </Table>

                     
                </Row> 
            </div>

            <Row style={{paddingLeft:'40px', paddingRight:'40px'}}>  
            
                {avis.etat == 0 ? 
                    <Col xs={12} sm={4} className='btn-deposer-in-accueil'><Button onClick={validerAvis} style={{background:'green', color:'#fff'}}> Activer </Button> </Col>
                : avis.etat == 1 ?    
                    <Col xs={12} sm={4} className='btn-deposer-in-accueil'><Button onClick={desactiverAvis} style={{background:'green', color:'#fff'}}> Desactiver </Button> </Col>
                : ''
                }
                
                {avis.etat != 2 ?
                    <Col xs={12} sm={4} className='btn-deposer-in-accueil'><Button onClick={annulerAvis} style={{background:'red', color:'#fff'}}> Annuler </Button></Col>
                    : avis.etat == 2 ?
                    <Col xs={12} sm={4} className='btn-deposer-in-accueil'><Button onClick={retournerAvis} style={{background:'red', color:'#fff'}}> Retoure </Button></Col>
                    : ''
                }
                <Col xs={12} sm={4} className='btn-deposer-in-accueil'><Button  style={{background:'blue', color:'#fff'}}> Mettre a l'une  </Button></Col>
            </Row>


            <div style={{margin:'30px', padding:'7px', border:'1px solid black', borderRadius:'5px'}}> 
                <Row style={{margin:'5px'}}>
                    <Col xs="12" lg="6">
                        <span className='fontAlmari' style={{fontWeight:'bold'}}>{t('patatger_annonce')} : </span>
                    </Col>

                    <Col xs="12" lg="6" style={{textAlign:'end'}}>
                        <span className='fontAlmari' style={{fontWeight:'bold'}}>{t('publie_le')} : </span> {Moment(avis.created_at).format('DD MMM YYYY')}
                    </Col> 
                </Row>
            </div> 
            
        </>
    );
};

export default AdminAfficherDetailAvis;