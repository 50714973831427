import React from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Badge from "react-bootstrap/Badge";
import { useTranslation } from "react-i18next";
import "../css/style_cv_acc_fr.css";

const RegroupementCvsDansAcuueil = (props) => {
  const { t, i18n } = useTranslation();
  // import("../css/style_cv_acc_" + i18n.language + ".css");

  return (
    <>
      <Row style={{ margin: "5px" }}>
        <Col xs="12" lg="4">
          <Row>
            <header
              className="fontAlmari"
              style={{
                textAlign: "center",
                fontWeight: "bold",
                marginBottom: "5px",
                padding: "10px",
              }}
            >
              {t("regroupement_cv_dans_accueil")}
            </header>
            {props.cvsParDomaine.map((o, index) => (
              <Col
                key={index}
                xs="12"
                lg="6"
                className="col3"
                style={{
                  background: "rgb(173 8 31 / 15%)",
                  padding: "10px",
                  fontSize: "12px",
                }}
              >
                <Row className="widget-box">
                  <Col xs="10" lg="9">
                    <p className="fontAlmari">{t(o.domain_d_etude)}</p>
                  </Col>
                  <Col
                    xs="2"
                    lg="3"
                    style={{ paddingLeft: "8px", textAlign: "end" }}
                  >
                    <Badge bg="success">
                      {(
                        (o.nbr * 100) /
                        props.cvsParDomaine.reduce(
                          (a, v) => (a = a + parseInt(v.nbr)),
                          0
                        )
                      ).toFixed(2)}
                      %
                    </Badge>
                  </Col>
                </Row>
              </Col>
            ))}
          </Row>
        </Col>

        <Col xs="12" lg="4">
          <Row>
            <header
              className="fontAlmari"
              style={{
                textAlign: "center",
                fontWeight: "bold",
                marginBottom: "5px",
                padding: "10px",
              }}
            >
              {t("regroupement_cv_par_niveau_dans_accueil")}
            </header>
            {props.cvsParNiveau.map((o, index) => (
              <Col
                key={index}
                xs="12"
                lg="6"
                className="col3"
                style={{
                  background: "#25502547",
                  padding: "10px",
                  fontSize: "12px",
                }}
              >
                <Row className="widget-box">
                  <Col xs="10" lg="9">
                    <p className="fontAlmari">{t(o.niveau)}</p>
                  </Col>
                  <Col
                    xs="2"
                    lg="3"
                    style={{ paddingLeft: "8px", textAlign: "end" }}
                  >
                    <Badge bg="danger">
                      {/* {o.nbr} */}
                      {(
                        (o.nbr * 100) /
                        props.cvsParNiveau.reduce(
                          (a, v) => (a = a + parseInt(v.nbr)),
                          0
                        )
                      ).toFixed(2)}
                      %
                    </Badge>
                  </Col>
                </Row>
              </Col>
            ))}
          </Row>
        </Col>

        <Col xs="12" lg="4">
          <Row>
            <header
              className="fontAlmari"
              style={{
                textAlign: "center",
                fontWeight: "bold",
                marginBottom: "5px",
                padding: "10px",
              }}
            >
              {t("regroupement_cv_par_age_dans_accueil")}
            </header>

            {!isNaN(
              (
                (props.inf20 * 100) /
                (props.inf20 +
                  props.entre20et30 +
                  props.entre30et45 +
                  props.sup45)
              ).toFixed(2)
            ) ? (
              <>
                <Col
                  xs="12"
                  lg="6"
                  className="col3"
                  style={{ background: "#fff", fontSize: "12px" }}
                >
                  <Row className="widget-box">
                    <Col xs="8" lg="8" style={{ padding: "10px" }}>
                      {" "}
                      <p className="fontAlmari">{t("inf_20_ans")}</p>{" "}
                    </Col>
                    <Col
                      xs="4"
                      lg="4"
                      style={{ padding: "10px", textAlign: "end" }}
                    >
                      <Badge className="fontAlmari" bg="primary">
                        {(
                          (props.inf20 * 100) /
                          (props.inf20 +
                            props.entre20et30 +
                            props.entre30et45 +
                            props.sup45)
                        ).toFixed(2)}
                        %
                      </Badge>
                    </Col>
                  </Row>
                </Col>

                <Col
                  xs="12"
                  lg="6"
                  className="col3"
                  style={{ background: "#fff", fontSize: "12px" }}
                >
                  <Row className="widget-box">
                    <Col xs="8" lg="8" style={{ padding: "10px" }}>
                      {" "}
                      <p className="fontAlmari">{t("entre_21_30")}</p>{" "}
                    </Col>
                    <Col
                      xs="4"
                      lg="4"
                      style={{ padding: "10px", textAlign: "end" }}
                    >
                      <Badge className="fontAlmari" bg="warning">
                        {(
                          (props.entre20et30 * 100) /
                          (props.inf20 +
                            props.entre20et30 +
                            props.entre30et45 +
                            props.sup45)
                        ).toFixed(2)}
                        %
                      </Badge>
                    </Col>
                  </Row>
                </Col>

                <Col
                  xs="12"
                  lg="6"
                  className="col3"
                  style={{ background: "#fff", fontSize: "12px" }}
                >
                  <Row className="widget-box">
                    <Col xs="8" lg="8" style={{ padding: "10px" }}>
                      {" "}
                      <p className="fontAlmari">{t("entre_31_45")}</p>{" "}
                    </Col>
                    <Col
                      xs="4"
                      lg="4"
                      style={{ padding: "10px", textAlign: "end" }}
                    >
                      <Badge className="fontAlmari" bg="danger">
                        {(
                          (props.entre30et45 * 100) /
                          (props.inf20 +
                            props.entre20et30 +
                            props.entre30et45 +
                            props.sup45)
                        ).toFixed(2)}
                        %
                      </Badge>
                    </Col>
                  </Row>
                </Col>

                <Col
                  xs="12"
                  lg="6"
                  className="col3"
                  style={{ background: "#fff", fontSize: "12px" }}
                >
                  <Row className="widget-box">
                    <Col xs="8" lg="8" style={{ padding: "10px" }}>
                      {" "}
                      <p className="fontAlmari">{t("sup_45")}</p>{" "}
                    </Col>
                    <Col
                      xs="4"
                      lg="4"
                      style={{ padding: "10px", textAlign: "end" }}
                    >
                      <Badge className="fontAlmari" bg="success">
                        {(
                          (props.sup45 * 100) /
                          (props.inf20 +
                            props.entre20et30 +
                            props.entre30et45 +
                            props.sup45)
                        ).toFixed(2)}
                        %
                      </Badge>
                    </Col>
                  </Row>
                </Col>
              </>
            ) : null}
          </Row>
        </Col>
      </Row>
    </>
  );
};

export default RegroupementCvsDansAcuueil;
