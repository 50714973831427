import React from "react";
import { useState, useEffect } from "react";
import axios from "../lib/axios";
import { Tab, Tabs, Button, Row, Col, Spinner } from "react-bootstrap";
import Avis from "../components/Avis";
import OffreEmploi from "../components/OffreEmploi";
import AppelOffres from "../components/AppelOffres";
import ListCvsDansAccueil from "../components/listCvsDansAccueil";
import RegroupementCvsDansAcuueil from "../components/regroupementCvsDansAcuueil";
import ModelCvsEtMotivation from "../components/modelCvsEtMotivation";
import AlaUne from "../components/AlaUne";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faUsersCog,
  faFileSignature,
  faFileInvoiceDollar,
} from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { isMobile } from "react-device-detect";
import { Helmet } from "react-helmet-async";

const Home = () => {
  const [offresEmploi, setOffresEmploi] = useState([]);
  const [appelsOffres, setAppelsOffres] = useState([]);
  const [appelsOffresCount, setAppelsOffresCount] = useState(0);
  const [autrePublications, setAutrePublications] = useState([]);
  const [autrePublicationsCount, setAutrePublicationsCount] = useState(0);
  const [cvs, setCvs] = useState([]);
  const [offerEmploiesAlaUne_fr, setOfferEmploiesAlaUne_fr] = useState([]);
  const [offerEmploiesAlaUne_ar, setOfferEmploiesAlaUne_ar] = useState([]);
  const [appeleOffersAlaUne_fr, setAppeleOffersAlaUne_fr] = useState([]);
  const [appeleOffersAlaUne_ar, setAppeleOffersAlaUne_ar] = useState([]);
  const [inf20, setInf20] = useState(0);
  const [entre20et30, setEntre20et30] = useState(0);
  const [entre30et45, setEntre30et45] = useState(0);
  const [sup45, setSup45] = useState(0);
  const [cvsParDomaine, setCvsParDomaine] = useState([]);
  const [cvsParNiveau, setCvsParNiveau] = useState([]);
  const [openW, setOpenW] = React.useState(false);
  const [onglet, setOnglet] = useState("OffresEmploi");
  const { t, i18n } = useTranslation();
  const [titreTabAvia, setTitreTabAvia] = useState("");
  const getActiveTab = JSON.parse(sessionStorage.getItem("activeTab"));
  const applesOffresDansSession = JSON.parse(
    sessionStorage.getItem("appelsOffresInSession")
  );
  const AvisInSession = JSON.parse(sessionStorage.getItem("AvisInSession"));

  useEffect(() => {
    let langDemande = "fr";
    if (window.location.href.indexOf("/ar/") > -1) {
      langDemande = "ar";
    }

    if (i18n.language != langDemande) {
      setLanguage();
      window.location.reload();
    }

    document.title = t("titre_rimtic");
    getOffresIndex();

    if (getActiveTab) {
      setOnglet(getActiveTab);
    }
    if (isMobile) {
      setTitreTabAvia(t("info_avis"));
    } else {
      setTitreTabAvia(t("publi_avis"));
    }
  }, []);

  const setLanguage = () => {
    i18n.changeLanguage("fr");
    if (window.location.href.indexOf("/ar/") > -1) {
      i18n.changeLanguage("ar");
    }
  };

  const getOffresIndex = () => {
    sessionStorage.removeItem("appelsOffresInSession");
    sessionStorage.removeItem("AvisInSession");
    sessionStorage.setItem("activeTab", JSON.stringify("OffresEmploi"));
    setOpenW(true);

    axios
      .get("/api/home")
      .then((response) => {
        setOffresEmploi(response.data.offresEmploi);
        setAppelsOffresCount(response.data.appelsOffresCount);
        setAutrePublicationsCount(response.data.autrePublicationsCount);
        sessionStorage.setItem(
          "domaines",
          JSON.stringify(response.data.domainesOffre)
        );
        if (applesOffresDansSession != null) {
          setAppelsOffres(applesOffresDansSession);
        }
        if (AvisInSession != null) {
          setAutrePublications(AvisInSession);
        }
        setCvs(response.data.sixcv);
        setCvsParDomaine(response.data.cvsbydomain);
        setCvsParNiveau(response.data.cvsbyniveau);
        setInf20(response.data.inf20);
        setEntre20et30(response.data.entre20et30);
        setEntre30et45(response.data.entre30et45);
        setSup45(response.data.sup45);
        setOfferEmploiesAlaUne_fr(response.data.offerEmploiesAlaUne_fr);
        setOfferEmploiesAlaUne_ar(response.data.offerEmploiesAlaUne_ar);
        setAppeleOffersAlaUne_fr(response.data.appeleOffersAlaUne_fr);
        setAppeleOffersAlaUne_ar(response.data.appeleOffersAlaUne_ar);
        setOpenW(false);

        sessionStorage.setItem("adds", JSON.stringify(response.data.adds));
      })
      .catch((error) => {
        console.log(error);
        setOpenW(false);
      });
  };

  const getApplesOffres = () => {
    if (applesOffresDansSession != null) {
      setAppelsOffres(applesOffresDansSession);
    } else {
      setOpenW(true);
      axios
        .get("/api/applesOffres", {
          headers: {
            Accept: "application/json",
          },
        })
        .then((response) => {
          setAppelsOffres(response.data.appelsOffres);
          sessionStorage.setItem(
            "appelsOffresInSession",
            JSON.stringify(response.data.appelsOffres)
          );
          setOpenW(false);
        })
        .catch((error) => {
          console.log(error);
          setOpenW(false);
        });
    }
  };

  const getAvis = () => {
    if (AvisInSession != null) {
      setAppelsOffres(applesOffresDansSession);
    } else {
      setOpenW(true);
      axios
        .get("/api/avis", {
          headers: {
            Accept: "application/json",
          },
        })
        .then((response) => {
          setAutrePublications(response.data.autrePublications);
          sessionStorage.setItem(
            "AvisInSession",
            JSON.stringify(response.data.autrePublications)
          );
          setOpenW(false);
        })
        .catch((error) => {
          console.log(error);
          setOpenW(false);
        });
    }
  };

  return (
    <>
      <Helmet>
        <meta
          name="description"
          content="RIMTIC - Portail de l'emploi, recrutement et appels d'offres en Mauritanie"
        />
      </Helmet>

      {offerEmploiesAlaUne_fr.length != 0 ||
      offerEmploiesAlaUne_ar.length != 0 ||
      appeleOffersAlaUne_fr.length != 0 ||
      appeleOffersAlaUne_ar.length != 0 ? (
        <AlaUne
          offresEmploisAlaUne_fr={offerEmploiesAlaUne_fr}
          offresEmploisAlaUne_ar={offerEmploiesAlaUne_ar}
          appelsOffresAlaUne_fr={appeleOffersAlaUne_fr}
          appelsOffresAlaUne_ar={appeleOffersAlaUne_ar}
        />
      ) : null}

      <Row
        className="row-btn-deposer  d-none d-sm-inline-flex"
        style={{ margin: "auto" }}
      >
        <Col xs={12} sm={4} className="btn-deposer-in-accueil">
          <Button
            className="btn btn-2 fontAlmari"
            as={Link}
            to={`/${i18n.language}/deposer_offre_emploi`}
          >
            <FontAwesomeIcon icon={faUsersCog} /> {t("depose_offre_emploi")}
          </Button>
        </Col>
        <Col xs={12} sm={4} className="btn-deposer-in-accueil">
          <a
            className="btn btn-3 fontAlmari"
            href="https://rimtic.com/cvtheque-f/inscrire"
          >
            <FontAwesomeIcon icon={faFileSignature} /> {t("deposer_votre_CV")}
          </a>
        </Col>
        <Col xs={12} sm={4} className="btn-deposer-in-accueil">
          <Button
            className="btn btn-4 fontAlmari"
            as={Link}
            to={`/${i18n.language}/deposer_appel_offres`}
          >
            <FontAwesomeIcon icon={faFileInvoiceDollar} />{" "}
            {t("depose_appel_offre")}
          </Button>
        </Col>
      </Row>

      <Tabs
        className="tabs-in-accueil fontAlmari"
        id="controlled-tab-example"
        activeKey={onglet}
        onSelect={(k) => {
          setOnglet(k);
          sessionStorage.setItem("activeTab", JSON.stringify(k));
          if (k == "AppelsOffres") {
            getApplesOffres();
          } else if (k == "Avis") {
            getAvis();
          }
        }}
        justify
      >
        <Tab
          className="offresEmploi tab-inaccueil p-1 fontAlmari"
          eventKey="OffresEmploi"
          title={offresEmploi.length + " " + t("offre_emploi")}
        >
          {openW && (
            <div className="text-center">
              <Spinner animation="border" variant="danger" />
            </div>
          )}
          <Row className="mx-0 pt-1">
            {offresEmploi?.map((o) =>
              i18n.language == "ar" &&
              o.libelle_ar != null &&
              o.libelle_ar != "null" &&
              o.ville_ar != "null" &&
              o.contenue_ar != "null" ? (
                <Col key={o.id} xs="12" lg="4" className="pe-3">
                  <OffreEmploi avis={o} />
                </Col>
              ) : i18n.language == "fr" && o.libelle != null ? (
                <Col key={o.id} xs="12" lg="4" className="pe-3">
                  <OffreEmploi avis={o} />
                </Col>
              ) : null
            )}
          </Row>
        </Tab>

        <Tab
          className="AppelsOffres tab-inaccueil p-1 fontAlmari"
          eventKey="AppelsOffres"
          title={appelsOffresCount + " " + t("apple_offre")}
        >
          {openW && (
            <div className="text-center">
              <Spinner animation="border" variant="danger" />
            </div>
          )}

          <Row className="mx-0 pt-1">
            {appelsOffres?.map((o) =>
              i18n.language == "ar" &&
              o.libelle_ar != null &&
              o.libelle_ar != "null" &&
              o.ville_ar != "null" &&
              o.contenue_ar != "null" ? (
                <Col key={o.id} xs="12" lg="4" className="pe-3">
                  <AppelOffres avis={o} />
                </Col>
              ) : i18n.language == "fr" && o.libelle != null ? (
                <Col key={o.id} xs="12" lg="4" className="pe-3">
                  <AppelOffres avis={o} />
                </Col>
              ) : null
            )}
          </Row>
        </Tab>

        <Tab
          className="Pub tab-inaccueil p-1 fontAlmari"
          eventKey="Avis"
          title={autrePublicationsCount + " " + titreTabAvia}
        >
          {openW && (
            <div className="text-center">
              <Spinner animation="border" variant="danger" />
            </div>
          )}
          <Row className="mx-0 pt-1">
            {autrePublications?.map((o) =>
              i18n.language == "ar" &&
              o.libelle_ar != null &&
              o.libelle_ar != "null" &&
              o.contenue_ar != "null" ? (
                <Col key={o.id} xs="12" lg="4" className="pe-3">
                  <Avis avis={o} />
                </Col>
              ) : i18n.language == "fr" && o.libelle != null ? (
                <Col key={o.id} xs="12" lg="4" className="pe-3">
                  <Avis avis={o} />
                </Col>
              ) : null
            )}
          </Row>
        </Tab>
      </Tabs>

      <div style={{ background: "#EEE" }}>
        <ModelCvsEtMotivation />
        <ListCvsDansAccueil cvs={cvs}></ListCvsDansAccueil>

        <RegroupementCvsDansAcuueil
          inf20={inf20}
          entre20et30={entre20et30}
          entre30et45={entre30et45}
          sup45={sup45}
          cvsParDomaine={cvsParDomaine}
          cvsParNiveau={cvsParNiveau}
        ></RegroupementCvsDansAcuueil>

        <div style={{ marginRight: "3px", marginLeft: "3px" }}>
          <a
            className="btn btn-3 fontAlmari"
            href="https://rimtic.com/cvtheque-f/inscrire"
          >
            {t("deposez_gerer_votre_CV")}
          </a>
        </div>
      </div>
    </>
  );
};

export default Home;
