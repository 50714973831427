import React, {useState, useEffect } from 'react';
import { Container } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';  
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';  
import Table from 'react-bootstrap/Table'; 
import axios from '../../lib/axios';
import EnteteAdmin from '../../components/componentAdmin/EnteteAdmin'
import { useParams, Link, useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from 'react-i18next';
import { CKEditor } from 'ckeditor4-react';

const EditerOffreEmploi = () => {
    const [text, setText] = useState('');
    const [counter, setCounter] = useState(0);
    
    const [societes, setSocietes] = useState([]);
    const [societeSelected, setSocieteSelected] = useState({});
    const [searchInput, setSearchInput] = useState('');
    const [filteredResults, setFilteredResults] = useState([]);
    const [domaines, setDomaines] = useState([]);
    const [offreEmploi, setOffreEmploi] = useState({});
    const [idOffreEmploi, setIdOffreEmploi] = useState(0);
    const [sosId, setSosId] = useState(0);
    const [nomSociete, setNomSociete] = useState(null);
    const [logo, setLogo] = useState('');
    const [adresse, setAdresse] = useState('');
    const [adresseAr, setAdresseAr] = useState();
    const [libelle, setLibelle] = useState('');
    const [libelleAr, setLibelleAr] = useState('');
    const [introduction, setIntroduction] = useState('');
    const [fin, setFin] = useState('');
    const [debut, setDebut] = useState('');
    const [contenue, setContenue] = useState('');
    const [contenueAr, setContenueAr] = useState('');
    const [postule, setPostule] = useState(''); 
    // 4i lahi nseto vihe les pieces li kano deja houne
    const [piecejointes, setPiecejointes] = useState([]);
    // 4i eyak n2ajouto des nouveau piecejoint
    const [pieceJointes, setPieceJointes] = useState([]); 
    const [domaine, setDomaine] = useState('');
    const [niveau, setNiveau] = useState([]);
    const [experciance, setExperciance] = useState([]);
    const {id} = useParams();
    const navigate = useNavigate();
    const { t, i18n } = useTranslation();
    const [list, setList] = useState([0]); 

    useEffect(() => {
        getSocietes(); 
        getOffreEmploi();
        getDomaines();
    }, []);

    const getDomaines = () => { 
        axios.get('/api/listDomaines', {
            headers: {
                "Accept": "application/json"
            }
        }).then(response => { 
            setDomaines(response.data.domaines) 
        }).catch(error => {
            console.log(error); 
        });

    };

    const ajouterAuterFichier = () => { 
        setList([...list, list.length])
    };

    const removeFichier = (value, index) => {  
        var arr = list.filter(item => item !== value)
        var pj = pieceJointes.filter((item, i) => i !== index) 
        setList(arr)
        setPieceJointes(pj) 
    }

    const searchItems = (searchValue) => {
        setSearchInput(searchValue)
        if (searchInput !== '') {
            const filteredData = societes.filter((item) => {
                return Object.values(item).join('').toLowerCase().includes(searchInput.toLowerCase())
            })
            setFilteredResults(filteredData)
        }
        else{
            setFilteredResults([])
        }
    }

    const selectItems = (e) => { 
        const id = e.target.value;
        const sos = societes.find(item => item.id == id);
        setSocieteSelected(sos);
        setSosId(sos.id) 
    }

    const getSocietes = () => { 
        axios.get('/api/listSocietes', {
            headers: {
                "Accept": "application/json"
            }
        }).then(response => { 
            setSocietes(response.data.societes) 
        }).catch(error => {
            console.log(error); 
        });

    };

    const getOffreEmploi = () => { 
        axios.get(`api/tic/demploie/modifier/${id}`, {
            headers: {
                "Accept": "application/json"
            }
        }).then(response => { 
            setOffreEmploi(response.data.offeremp) 
            setIdOffreEmploi(response.data.offeremp.id) 
            setNomSociete(response.data.offeremp.societe.nom) 
            setLogo(response.data.offeremp.societe.logo) 
            setAdresse(response.data.offeremp.ville) 
            setAdresseAr(response.data.offeremp.ville_ar) 
            setLibelle(response.data.offeremp.libelle) 
            setLibelleAr(response.data.offeremp.libelle_ar) 
            setFin(response.data.offeremp.fin) 
            setDebut(response.data.offeremp.debut) 
            setContenue(response.data.offeremp.contenue) 
            setContenueAr(response.data.offeremp.contenue_ar) 
            setDomaine(response.data.offeremp.domaine) 
            setNiveau(response.data.offeremp.niveau) 
            setExperciance(response.data.offeremp.experience) 
            setPiecejointes(response.data.pieceJointes)  
           

        }).catch(error => {
            console.log(error); 
        });

    };

    const modifierOffreEmploi = (e) => { 
        const form = new FormData(); 
        form.append('idOffreEmploi', idOffreEmploi);
        form.append('sosId', sosId);
        form.append('nomSociete', nomSociete);
        form.append('adresse', adresse);
        form.append('adresse_ar', adresseAr);
        form.append('libelle', libelle);
        form.append('libelle_ar', libelleAr);
        form.append('introduction', introduction);
        form.append('fin', fin);
        form.append('debut', debut);
        if(contenue != null){ 
            form.append('contenue', contenue.printHeaderText);
        }
        if(contenueAr != null){ 
            form.append('contenue_ar', contenueAr.printHeaderTextAr);
        }

        form.append('postule', postule);
        form.append('logo', logo); 
        form.append('domaine', domaine); 
        form.append('niveau', niveau); 
        form.append('experience', experciance);
        
        pieceJointes.forEach(element => {
            form.append('piecejointe[]', element); 
        });

        
        

        axios.post(`/api/tic/demploie/modifier/${id}`, form, {
            headers: {
                'Accept': 'application/json', 
            }
        }).then(response => {   
          navigate(`/tic/demploie/${id}`) 
           
        }).catch(error => {
            console.log(error)    
        });
      
    }; 


    const supprimerPJ = (e) => { 
        const idPj = e.target.value;
        const idPj2 = parseInt(idPj) 
        axios.get(`/api/tic/supprimer_pj/${idPj2}`, {
            headers: {
                "Accept": "application/json"
            }
        }).then(response => {  
            getOffreEmploi();

        }).catch(error => {
            console.log(error); 
        });

    }

    const changeContenueText = (value) => {  
        setContenue((prev)=>{
            return {
                ...prev,
                printHeaderText: value.editor.getData()
            }
        }) 
        
    }

    const changeContenueTextAr = (value) => { 
        setContenueAr((prev)=>{
            return {
                ...prev,
                printHeaderTextAr: value.editor.getData()
            }
        }) 
     }


    let editorToolbar = [
        { name: "tools", items: ["Maximize"] },
        {
            name: "clipboard",
            items: ["Cut", "Copy", "Paste", "PasteText", "-", "Undo", "Redo"]
        },
        { name: "links", items: ["Link", "Unlink"] },
        { name: "document", items: ["Source"] }, 
        {
            name: "basicstyles",
            items: [
            "Bold",
            "Italic",
            "Underline",
            "Strike",
            "-",
            "Subscript",
            "Superscript"
            ]
        },
        {
            name: "paragraph",
            items: [
            "NumberedList",
            "BulletedList",
            "-",
            "Outdent",
            "Indent",
            "Blockquote"
            ]
        },
        {
            name: "align",
            items: [
            "AlignLeft",
            "JustifyLeft",
            "JustifyCenter",
            "JustifyRight",
            "JustifyBlock"
            ]
        }, 
        { name: "styles", items: ["Format", "-", "Font", "-", "FontSize"] },
        { name: "colors", items: ["TextColor", "BGColor"] },
        { name: "insert", items: ["Image", "Table", "HorizontalRule"] },
        "/"
        
    ]

    return (

        <>
            <EnteteAdmin></EnteteAdmin>
            <Container>
 
                <div>
                    <p className='fontAlmari' style={{border:'solid 2px', borderLeft:'0px', borderRight:'0px', borderColor:'black', textAlign:'center', fontSize:'25px', marginTop:'10px', padding:'10px'}}>{t('mod_offre_emploi')}</p>
                   
                </div> 

                <div>  
                    <Row style={{marginTop:"15px", background:'#EEE', margin:'15px 1px 0px 1px', paddingBottom:'10px'}}>
                        <input type="text" value={idOffreEmploi} hidden />
                        <Col xs={12} sm={6}>  
                            <Form.Label className='fontAlmari'>{t('societe')}</Form.Label> 
                            <input type="text" value={sosId} hidden />
                            {Object.keys(societeSelected).length !== 0  ? 
                                <>
                                    <Form.Control type="text" value={societeSelected.nom} onChange={(e) => {searchItems(e.target.value); setSocieteSelected({});}}/>
                                
                                </>
                                : 
                                <>
                                <Form.Control type="text" value={nomSociete}  onChange={(e) => {searchItems(e.target.value); setNomSociete(e.target.value)}} />
                                    {searchInput.length > 2 ? (
                                        filteredResults.map((item) => {
                                            return (    
                                                <option  key={item.id} value={item.id} onClick={selectItems}>{item.nom}</option>                        
                                            )
                                        })
                                        ) : ''
                                    } 
                                </>
                                }   
                        </Col>

                        <Col xs={12} sm={6}>
                            <Form.Label className='fontAlmari'>{t('logo')}</Form.Label> 
                            {Object.keys(societeSelected).length !== 0 ?
                            <>
                            <br/>
                            <img src={'https://rimtic.com/storage/images/'+ societeSelected.logo} style={{height:'40px', width:'70px'}}/> 
                            </>
                            : 
                            <>
                            <img src={'https://rimtic.com/storage/images/'+ logo} style={{height:'40px', width:'70px'}}/> 
                            <Form.Control type="file"  onChange={(e) => setLogo(e.target.files[0])}/>
                            </>
                            }
                        </Col> 
                        <Col xs={12} sm={6}>
                            <Form.Label className='fontAlmari'>المدينة</Form.Label> 
                            <Form.Control type="text" value={adresseAr} onChange={(e) => setAdresseAr(e.target.value)}/> 
                        </Col>

                        <Col xs={12} sm={6}>
                            <Form.Label className='fontAlmari'>Ville</Form.Label>
                            <Form.Control type="text" value={adresse} onChange={(e) => setAdresse(e.target.value)}/> 
                        </Col>
                    </Row> 

                    <Row style={{margin:'10px 0px 5px 0px'}}>

                        <Col xs={12} sm={6}>  
                            <Form.Label className='fontAlmari'>العنوان</Form.Label>
                            <Form.Control type="text" value={libelleAr} onChange={(e) => setLibelleAr(e.target.value)}/> 
                        </Col>

                        <Col xs={12} sm={6}>  
                            <Form.Label className='fontAlmari'>Titre</Form.Label>
                            <Form.Control type="text" value={libelle} onChange={(e) => setLibelle(e.target.value)}/> 
                        </Col>

                        <Col xs={12} sm={3}>
                            <Form.Label className='fontAlmari'>{t('date_debut_cand')}</Form.Label>
                            <Form.Control type="date" value={debut} onChange={(e) => setFin(e.target.value)} />
                        </Col>

                        <Col xs={12} sm={3}>
                            <Form.Label className='fontAlmari'>{t('date_limite')}</Form.Label>
                            <Form.Control type="date" value={fin} onChange={(e) => setFin(e.target.value)} />
                        </Col>
 
                        <Col xs={12} sm={12} style={{marginTop:'10px'}}>
                            <Form.Label className='fontAlmari'>المحتوي</Form.Label>
                            {
                                 offreEmploi.contenue_ar && <CKEditor   
                                    config={{toolbar: editorToolbar,
                                    extraPlugins: "colorbutton,colordialog,font,justify",
                                    removeButtons: ""}} 
                                    initData={offreEmploi.contenue_ar} 
                                    onChange={changeContenueTextAr}
                                /> 

                            }
                            {
                                !offreEmploi.contenue_ar && <CKEditor   
                                    config={{toolbar: editorToolbar,
                                    extraPlugins: "colorbutton,colordialog,font,justify",
                                    removeButtons: ""}} 
                                    onChange={changeContenueTextAr}
                                /> 
                            }
                        </Col>

                       
                        <Col xs={12} sm={12} style={{marginTop:'10px'}}>
                            <Form.Label >Contenue </Form.Label> 
                            
                            {
                                offreEmploi.contenue && <CKEditor   
                                    config={{toolbar: editorToolbar,
                                    extraPlugins: "colorbutton,colordialog,font,justify",
                                    removeButtons: ""}} 
                                    initData={offreEmploi.contenue} 
                                    onChange={changeContenueText}
                                />
                            }
                            {
                                !offreEmploi.contenue && <CKEditor   
                                    config={{toolbar: editorToolbar,
                                    extraPlugins: "colorbutton,colordialog,font,justify",
                                    removeButtons: ""}}   
                                    onChange={changeContenueText}
                                />
                            }
                            
                        </Col>
                        
                        <Col xs={12} sm={12} style={{marginTop:'50px'}}>
                            <Row>
                                <Col xs={12} sm={12} style={{margin:'10px 0px 5px 0px'}}>
                                    <p className='fontAlmari' style={{fontWeight:'bold', color:'black'}}>{t('autre_info')} :</p>
                                </Col>

                                <Col xs="12" lg="4">
                                    <Form.Select aria-label="Default select example" value={domaine} onChange={(e) => setDomaine(e.target.value)}  >
                                        {
                                        domaines.map( (domaine,index) =>
                                            i18n.language== 'fr' ?
                                            <option key={index} value={domaine.code}>{domaine.nom}</option> 
                                            :
                                            <option key={index} value={domaine.code}>{domaine.nom_ar}</option> 
                                        )}

                                        {/* <option  value="autres">{t('autres')}</option>
                                        <option value="ressource">{t('ressource')}</option>
                                        <option value="commercial">{t('commercial')}</option>
                                        <option value="informatique">{t('informatique')}</option>
                                        <option value="transport">{t('transport')}</option> 
                                        <option value="maintenance">{t('maintenance')}</option>
                                        <option value="tourisme">{t('tourisme')}</option>
                                        <option value="gestion">{t('gestion')}</option>
                                        <option value="environement">{t('environement')}</option>
                                        <option value="securite">{t('securite')}</option>
                                        <option value="sante">{t('sante')}</option>
                                        <option value="secretriat">{t('secretriat')}</option>
                                        <option value="nutrition">{t('nutrition')}</option>
                                        <option value="langue">{t('langue')}</option>
                                        <option value="electricite_enrgie">{t('electricite_enrgie')}</option>
                                        <option value="chimie_bio">{t('chimie_bio')}</option>
                                        <option value="genie_civil">{t('genie_civil')}</option> */}
                                    </Form.Select>
                                </Col>

                                <Col xs="12" lg="4">
                                    <Form.Select aria-label="Default select example" value={niveau} onChange={(e) => setNiveau(e.target.value)} >
                                        <option value="sans">{t('sans')}</option>
                                        <option value="primaire">{t('primaire')}</option>
                                        <option value="brevet">{t('brevet')}</option>
                                        <option value="Bac">{t('Bac')}</option>
                                        <option value="Bac_2">{t('Bac_2')}</option>
                                        <option value="Bac_3">{t('Bac_3')}</option>
                                        <option value="Bac_4">{t('Bac_4')}</option>
                                        <option value="Bac_5">{t('Bac_5')}</option>
                                        <option value="doctorat">{t('doctorat')}</option>
                                        <option value="autres">{t('autres')}</option>
                                    </Form.Select>
                                </Col>

                                <Col xs="12" lg="4">
                                    <Form.Select aria-label="Default select example" value={experciance} onChange={(e) => setExperciance(e.target.value)} >
                                       <option value="0">0</option>
										<option value="1">1</option>
										<option value="2">2</option>
										<option value="3">3</option>
										<option value="4">4</option>
										<option value="5">5</option>
										<option value="6">6</option>
										<option value="7">7</option>
										<option value="8">8</option>
										<option value="9">9</option>	
										<option value="10">10</option>
										<option value="11">11</option>
										<option value="12">12</option>
										<option value="13">13</option>
										<option value="14">14</option>
										<option value="15">15</option>
                                    </Form.Select>
                                </Col>
                            </Row>     
                        </Col>

                    </Row> 

                    <Table striped bordered hover size="sm" style={{width:'96%', margin:'15px'}}> 
                        <tbody>
                            {piecejointes?.map((o) => ( 
                            <tr>
                                <td><img src="https://www.rimtic.com/images/icons/docx.png" alt="" style={{width:'32px'}} /></td>
                                <td><Link to="127.0.0.1:8000/storage/" target="_blank" download>{o.nom}</Link> </td>
                                <td> <Button onClick={supprimerPJ} value={o.id}><FontAwesomeIcon style={{color:'red'}} icon={faTrash}/></Button> </td> 
                            </tr> 
                            ))}
                            
                        </tbody>
                    </Table>
            


                    <Table> 
                        <tbody> 
                            {list.map((item, index) => {
                                return <tr key={item} > 
                                  
                                    <td style={{width:'80%'}}><Form.Control type="file"  onChange={(e) =>  setPieceJointes(arr => [...arr, e.target.files[0]])} /> </td>
                                     
                                    <td> 
                                        {
                                            list.length!==1 &&
                                            <Button  className='btn btn-success' style={{background:'none', color:'red', border:'0px',width:'20%'}} onClick={() =>removeFichier(item,index)}>X</Button>
                                        }
                                        { 
                                            list.length-1===index &&
                                            <Button className='btn btn-success fontAlmari' style={{background:'none', color:'black', width:'80%'}} onClick={ajouterAuterFichier}> {t('autre_fichier')}</Button>
                                        }
                                    </td>    
                                </tr>
                            })}
                        </tbody>
                    </Table> 

                    <div style={{textAlign:'center'}}> 
                        <Button className='btn btn-success fontAlmari' onClick={modifierOffreEmploi} style={{width:'190px', background:'green', color:"#fff"}}>{t('valider')}</Button>
                    </div>

                </div>
 
            </Container>  
        </>
    );
};

export default EditerOffreEmploi;;