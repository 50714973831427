import React from 'react';  
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileDownload, faFileWord, faCheckCircle} from '@fortawesome/free-solid-svg-icons'


const ModelCvsEtMotivation = (props) => {
    const { t, i18n } = useTranslation();

    return (
            <> 
             <Row style={{padding:'5px 0px 5px 0px', margin:'auto'}}> 
                <Col xs={12} sm={4} className='btn-deposer-in-accueil'><Button className='btn btn-2 fontAlmari' as={Link} to="/modelesCV"> <FontAwesomeIcon icon={faFileDownload}/> {t('model_cv')} </Button> </Col>
                <Col xs={12} sm={4} className='btn-deposer-in-accueil'><Button className='btn btn-3 fontAlmari' as={Link} to="/modelesLettres"> <FontAwesomeIcon icon={faFileWord}/> {t('model_motivation')} </Button></Col>
                <Col xs={12} sm={4} className='btn-deposer-in-accueil'><Button className='btn btn-4 fontAlmari' as={Link} to="/reussirVotreEntretien"> <FontAwesomeIcon icon={faCheckCircle}/> {t('reussir_votre_entretien')}</Button></Col>
            </Row> 
        </>
        
    );
};

export default ModelCvsEtMotivation;