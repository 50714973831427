import React, {useState, useEffect} from 'react';
import { Container } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';  
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';   
import Table from 'react-bootstrap/Table'; 
import axios from '../../lib/axios';
import EnteteAdmin from '../../components/componentAdmin/EnteteAdmin'
import { useParams, Link, useNavigate } from 'react-router-dom'; 
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from 'react-i18next';
import { CKEditor } from 'ckeditor4-react';


const EditerAvisPublication = () => {
    const [counter, setCounter] = useState(0);
    const [avis, setAvis] = useState({});
    const [idAvis, setIdAvis] = useState(0);
    const [societes, setSocietes] = useState([]);
    const [societeSelected, setSocieteSelected] = useState({});
    const [filteredResults, setFilteredResults] = useState([]);
    const [searchInput, setSearchInput] = useState('');
    const [sosId, setSosId] = useState(0);
    const [nomSociete, setNomSociete] = useState(null);
    const [logo, setLogo] = useState(null);
    const [adresse, setAdresse] = useState(null);
    const [libelle, setLibelle] = useState(null); 
    const [libelleAr, setLibelleAr] = useState(null); 
    const [fin, setFin] = useState(null);
    const [debut, setDebut] = useState(null);
    const [contenue, setContenue] = useState(null);
    const [contenueAr, setContenueAr] = useState(null);
    const [typeDocument, setTypeDocument] = useState(''); 
    // 4i lahi nseto vihe les pieces li kano deja houne
    const [piecejointes, setPiecejointes] = useState([]); 
    // 4i eyak n2ajouto des nouveau piecejoint
    const [pieceJointes, setPieceJointes] = useState([]); 
    const {id} = useParams();
    const navigate = useNavigate();
    const { t, i18n } = useTranslation();
    const [list, setList] = useState([0]);


    useEffect(() => {
        getSocietes();
        getAvis(); 
    }, []);

    const ajouterAuterFichier = () => { 
        setList([...list, list.length])
    };

    const removeFichier = (value, index) => {  
        var arr = list.filter(item => item !== value)
        var pj = pieceJointes.filter((item, i) => i !== index) 
        setList(arr)
        setPieceJointes(pj) 
    }

    const getSocietes = () => { 
        axios.get('/api/listSocietes', {
            headers: {
                "Accept": "application/json"
            }
        }).then(response => { 
            setSocietes(response.data.societes) 
        }).catch(error => {
            console.log(error); 
        });

    };


    const searchItems = (searchValue) => {
        setSearchInput(searchValue)
        if (searchInput !== '') {
            const filteredData = societes.filter((item) => {
                return Object.values(item).join('').toLowerCase().includes(searchInput.toLowerCase())
            })
            setFilteredResults(filteredData)
        }
        else{
            setFilteredResults([])
        }
    }

    const selectItems = (e) => { 
        const id = e.target.value;
        const sos = societes.find(item => item.id == id);
        setSocieteSelected(sos);
        setSosId(sos.id) 
    }

    const getAvis = () => { 
        axios.get(`/api/tic/davis/modifier/${id}`, {
            headers: {
                "Accept": "application/json"
            }
        }).then(response => { 
            setAvis(response.data.avis)
            setIdAvis(response.data.avis.id)
            setSocietes(response.data.avis.societe)
            setNomSociete(response.data.avis.societe.nom)
            setLogo(response.data.avis.societe.logo)
            setLibelle(response.data.avis.libelle)
            setLibelleAr(response.data.avis.libelle_ar)
            setFin(response.data.avis.fin)
            setDebut(response.data.avis.debut)
            setContenue(response.data.avis.contenue)
            setContenueAr(response.data.avis.contenue_ar)
            setPiecejointes(response.data.pieceJointes) 
        }).catch(error => {
            console.log(error); 
        });

    };


    const modifierAvis = (e) => { 
        const form = new FormData(); 
        form.append('idAvis', idAvis);
        form.append('sosId', sosId);
        form.append('nomSociete', nomSociete);
        form.append('adresse', adresse);
        form.append('libelle', libelle); 
        form.append('libelle_ar', libelleAr); 
        form.append('fin', fin);
        form.append('debut', debut);
        if(contenue != null){ 
            form.append('contenue', contenue.printHeaderText);
        }
        if(contenueAr != null){ 
            form.append('contenue_ar', contenueAr.printHeaderTextAr);
        }  
        form.append('logo', logo); 
        pieceJointes.forEach(element => {
            form.append('piecejointe[]', element); 
        }); 

        axios.post(`/api/tic/davis/modifier/${id}`, form, {
            headers: {
                'Accept': 'application/json', 
            }
        }).then(response => {   
            navigate(`/tic/davis/${id}`);
         }).catch(error => {
            console.log(error)    
        });
      
    }; 

    const supprimerPJ = (e) => { 
        const idPj = e.target.value;
        const idPj2 = parseInt(idPj) 
        axios.get(`/api/tic/supprimer_pj/${idPj2}`, {
            headers: {
                "Accept": "application/json"
            }
        }).then(response => { 
            // setSocietes(response.data.societes) 
            getAvis();

        }).catch(error => {
            console.log(error); 
        });

    }

    const changeContenueText = (value) => { 
        setContenue((prev)=>{
            return {
                ...prev,
                printHeaderText: value.editor.getData()
            }
        }) 
    }
    
    const changeContenueTextAr = (value) => { 
        setContenueAr((prev)=>{
            return {
                ...prev,
                printHeaderTextAr: value.editor.getData()
            }
        }) 
    }


    let editorToolbar = [
        { name: "tools", items: ["Maximize"] },
        {
            name: "clipboard",
            items: ["Cut", "Copy", "Paste", "PasteText", "-", "Undo", "Redo"]
        },
        { name: "links", items: ["Link", "Unlink"] },
        { name: "document", items: ["Source"] }, 
        {
            name: "basicstyles",
            items: [
            "Bold",
            "Italic",
            "Underline",
            "Strike",
            "-",
            "Subscript",
            "Superscript"
            ]
        },
        {
            name: "paragraph",
            items: [
            "NumberedList",
            "BulletedList",
            "-",
            "Outdent",
            "Indent",
            "Blockquote"
            ]
        },
        {
            name: "align",
            items: [
            "AlignLeft",
            "JustifyLeft",
            "JustifyCenter",
            "JustifyRight",
            "JustifyBlock"
            ]
        }, 
        { name: "styles", items: ["Format", "-", "Font", "-", "FontSize"] },
        { name: "colors", items: ["TextColor", "BGColor"] },
        { name: "insert", items: ["Image", "Table", "HorizontalRule"] },
        "/"
        
    ]

    return ( 
        <>
            <EnteteAdmin></EnteteAdmin>
            <Container> 
                <div>
                    <p className='fontAlmari' style={{border:'solid 2px', borderLeft:'0px', borderRight:'0px', borderColor:'black', textAlign:'center', fontSize:'25px', marginTop:'10px', padding:'10px'}}>{t('mod_avis')}</p>
                </div> 

                <div>

                    <Row style={{marginTop:"15px", background:'#EEE', margin:'15px 1px 0px 1px', paddingBottom:'10px'}}>
                       <input type="text" hidden value={idAvis} />
                        <Col xs={12} sm={4}>  
                            <Form.Label className='fontAlmari'>{t('societe')}</Form.Label>
                            <input type="text" value={sosId} hidden />
                            {Object.keys(societeSelected).length !== 0  ? 
                                <>
                                    <Form.Control type="text" value={societeSelected.nom} onChange={(e) => {searchItems(e.target.value); setSocieteSelected({});  }}/>
                                </>
                                : 
                                <>
                                <Form.Control type="text"  value={nomSociete}  onChange={(e) => {searchItems(e.target.value); setNomSociete(e.target.value)}} />
                                    {searchInput.length > 2 ? (
                                        filteredResults.map((item) => {
                                            return (    
                                                <option  key={item.id} value={item.id} onClick={selectItems}>{item.nom}</option>                        
                                            )
                                        })
                                        ) : ''
                                    } 
                                </>
                                }    
                        </Col>

                        <Col xs={12} sm={4}>
                            <Form.Label className='fontAlmari'>{t('logo')}</Form.Label>
                            {Object.keys(societeSelected).length !== 0 ?
                            <>
                            <br/>
                            <img src={'https://www.rimtic.com/storage/images/'+ societeSelected.logo} style={{height:'40px', width:'70px'}}/> 
                            </>
                            : 
                            <>
                            <img src={'https://www.rimtic.com/storage/images/'+logo} style={{height:'40px', width:'70px'}}/> 
                            <Form.Control type="file"  onChange={(e) => setLogo(e.target.files[0])}/>
                            </>
                            }
                        </Col>
                        
                    </Row> 

                    <Row style={{margin:'10px 0px 5px 0px'}}>
                        <Col xs={12} sm={6}>  
                            <Form.Label className='fontAlmari'>العنوان</Form.Label>
                            <Form.Control type="text" value={libelleAr} onChange={(e) =>  setLibelleAr(e.target.value)}/> 
                        </Col>

                        <Col xs={12} sm={6}>  
                            <Form.Label className='fontAlmari'>Titre</Form.Label>
                            <Form.Control type="text" value={libelle} onChange={(e) =>  setLibelle(e.target.value)}/> 
                        </Col>

                        <Col xs={12} sm={3}>
                            <Form.Label className='fontAlmari'>{t('date_debut_cand')}</Form.Label>
                            <Form.Control type="date" value={debut} onChange={(e) =>  setDebut(e.target.value)}/>
                        </Col>

                        <Col xs={12} sm={3}>
                            <Form.Label className='fontAlmari'>{t('date_limite')}</Form.Label>
                            <Form.Control type="date" value={fin} onChange={(e) =>  setFin(e.target.value)}/>
                        </Col> 

                        <Col xs={12} sm={12} style={{marginTop:'10px'}}> 
                        <Form.Label className='fontAlmari'>المحتوي</Form.Label>
                            {
                                 avis.contenue_ar && <CKEditor   
                                    config={{toolbar: editorToolbar,
                                    extraPlugins: "colorbutton,colordialog,font,justify",
                                    removeButtons: ""}} 
                                    initData={avis.contenue_ar} 
                                    onChange={changeContenueTextAr}
                                /> 

                            }
                            {
                                !avis.contenue_ar && <CKEditor   
                                    config={{toolbar: editorToolbar,
                                    extraPlugins: "colorbutton,colordialog,font,justify",
                                    removeButtons: ""}}   
                                    onChange={changeContenueTextAr}
                                /> 
                            }
                        </Col> 

                        <Col xs={12} sm={12} style={{marginTop:'10px'}}>
                            <Form.Label className='fontAlmari'>Contenue</Form.Label> 
                            {
                                avis.contenue && <CKEditor   
                                    config={{toolbar: editorToolbar,
                                    extraPlugins: "colorbutton,colordialog,font,justify",
                                    removeButtons: ""}} 
                                    initData={avis.contenue} 
                                    onChange={changeContenueText}
                                />
                            }
                            {
                                !avis.contenue && <CKEditor   
                                    config={{toolbar: editorToolbar,
                                    extraPlugins: "colorbutton,colordialog,font,justify",
                                    removeButtons: ""}}    
                                    onChange={changeContenueText}
                                />
                            }
                        </Col> 
                    </Row> 

                    <Table striped bordered hover size="sm" style={{width:'95%', margin:'50px 0px 0px 40px'}}> 
                        <tbody>
                            {piecejointes?.map((o) => ( 
                            <tr>
                                <td><img src="https://www.rimtic.com/images/icons/docx.png" alt="" style={{width:'32px'}} /></td>
                                <td><Link to="127.0.0.1:8000/storage/" target="_blank" download style={{width:'100%'}}>{o.nom}</Link></td> 
                                <td> <Button onClick={supprimerPJ} value={o.id}><FontAwesomeIcon style={{color:'red'}} icon={faTrash}/></Button> </td>
                            </tr> 
                            ))}
                            
                        </tbody>
                    </Table>

                    <Table> 
                        <tbody> 
                            {list.map((item, index) => {
                                return <tr key={item} > 
                                  
                                    <td style={{width:'80%'}}><Form.Control type="file"  onChange={(e) =>  setPieceJointes(arr => [...arr, e.target.files[0]])} /> </td>
                                     
                                    <td> 
                                        {
                                            list.length!==1 &&
                                            <Button  className='btn btn-success' style={{background:'none', color:'red', border:'0px',width:'20%'}} onClick={() =>removeFichier(item,index)}>X</Button>
                                        }
                                        { 
                                            list.length-1===index &&
                                            <Button className='btn btn-success fontAlmari' style={{background:'none', color:'black', width:'80%'}} onClick={ajouterAuterFichier}> {t('autre_fichier')}</Button>
                                        }
                                    </td>    
                                </tr>
                            })} 
                        </tbody>
                    </Table>

                    <div style={{textAlign:'center'}}> 
                        <Button className='btn btn-success fontAlmari' style={{width:'190px', background:'green', color:"#fff"}} onClick={modifierAvis}>{t('valider')}</Button>
                    </div>

                </div>

            </Container> 
        </>

    );
};

export default EditerAvisPublication;