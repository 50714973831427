import React from "react";
import axios from "../lib/axios";
import { useParams } from "react-router-dom";
import { useState, useEffect } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Moment from "moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFileAlt,
  faClock,
  faEye,
  faMapMarker,
} from "@fortawesome/free-solid-svg-icons";
import { useTranslation } from "react-i18next";
import Spinner from "react-bootstrap/Spinner";
import Partager from "../components/partagerEtpublier";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet-async";

const OffreEmploiDetail = () => {
  const [offreEmploi, setOffreEmploi] = useState({});
  const [societe, setSociete] = useState({});
  const [pieceJointes, setPieceJointes] = useState([]);
  const { slug, id } = useParams();
  const { t, i18n } = useTranslation();
  const [openW, setOpenW] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    let langDemande = "fr";
    if (window.location.href.indexOf("/ar/") > -1) {
      langDemande = "ar";
    }

    if (i18n.language != langDemande) {
      i18n.changeLanguage(langDemande);
      window.location.reload();
    }

    getOffreEmploi();
  }, []);

  var detailAvis = {};

  i18n.language == "fr"
    ? (detailAvis = {
        id: offreEmploi.id,
        libelle: offreEmploi.libelle,
        contenue: offreEmploi.contenue,
        adresse: offreEmploi.ville,
      })
    : (detailAvis = {
        id: offreEmploi.id,
        libelle: offreEmploi.libelle_ar,
        contenue: offreEmploi.contenue_ar,
        adresse: offreEmploi.ville_ar,
      });

  const getOffreEmploi = () => {
    setOpenW(true);
    axios
      .get("/api/offre_emploi/" + slug + "/" + id)
      .then((response) => {
        if (response.data == "" || response.data.data.offreEmploi.etat == 2) {
          navigate("/");
        } else {
          setOffreEmploi(response.data.data.offreEmploi);
          setSociete(response.data.data.offreEmploi.societe);
          setPieceJointes(response.data.data.pieceJointes);
          setOpenW(false);
        }

        i18n.language == "fr"
          ? (document.title =
              t("off_emploi") +
              " - " +
              response.data.data.offreEmploi.libelle +
              " | " +
              response.data.data.offreEmploi.societe.nom)
          : (document.title =
              t("off_emploi") +
              " - " +
              response.data.data.offreEmploi.libelle_ar +
              " | " +
              response.data.data.offreEmploi.societe.nom);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <div style={{ padding: "20px" }}>
      <Helmet>
        <meta
          name="description"
          content={
            t("off_emploi") +
            " Mauritanie - " +
            offreEmploi.libelle +
            " | " +
            societe.nom
          }
        />
      </Helmet>
      {openW == true ? (
        <div className="text-center">
          <Spinner animation="border" variant="danger" />
        </div>
      ) : (
        <Row>
          <Col xs="12" lg="12">
            <center>
              {societe.logo && (
                <img
                  className="image"
                  src={"https://www.rimtic.com/storage/images/" + societe.logo}
                  alt={societe.nom}
                  style={{ maxWidth: "200px" }}
                />
              )}
              <p
                className="fontAlmari"
                style={{
                  textAlign: "center",
                  fontWeight: "bold",
                  marginTop: "1px",
                }}
              >
                {societe.nom}
              </p>
            </center>
          </Col>

          <Col xs="12" lg="12">
            <div
              className="dangerouslySetInnerHTML fontAlmari"
              style={{ textAlign: "center", fontSize: "20px", margin: "10px" }}
              dangerouslySetInnerHTML={{
                __html:
                  detailAvis.libelle == "null" ? null : detailAvis.libelle,
              }}
            ></div>
          </Col>

          <Col xs="12" lg="12">
            <div
              className="info-soci-emp"
              style={{ paddingRight: "50px", padding: "2px" }}
            >
              <p className="fontAlmari" style={{ fontSize: "13px" }}>
                <FontAwesomeIcon icon={faMapMarker} color="#255025" size="xs" />
                <span style={{ color: "rgb(173, 8,31)" }}>
                  {" "}
                  {detailAvis.adresse != "null" ? detailAvis.adresse : null}
                </span>
              </p>
              <p className="fontAlmari" style={{ fontSize: "13px" }}>
                <FontAwesomeIcon icon={faClock} color="#255025" size="xs" />
                <span style={{ color: "rgb(173, 8, 31)" }}>
                  {" "}
                  {Moment(offreEmploi.fin).format("DD/MM/YYYY")}
                </span>
              </p>
              <p className="fontAlmari" style={{ fontSize: "13px" }}>
                <FontAwesomeIcon icon={faEye} color="#255025" size="xs" />
                <span style={{ color: "rgb(173, 8, 31)" }}>
                  {" "}
                  {offreEmploi.counter}
                </span>
              </p>
              {offreEmploi.postule == 1 ? (
                <a
                  href={
                    process.env.NODE_ENV === "production"
                      ? process.env.REACT_APP_POSTULER_URL_PROD +
                        `/${offreEmploi.id}`
                      : process.env.REACT_APP_POSTULER_URL_LOCAL +
                        `/${offreEmploi.id}`
                  }
                  type="button"
                  className="btn btn-postuler fontAlmari"
                  style={{
                    marginTop: "5px",
                    background: "#047104",
                    color: "#fff",
                    width: "120px",
                  }}
                >
                  <FontAwesomeIcon
                    icon={faFileAlt}
                    color="#fff"
                    size="xs"
                    style={{ padding: "0px 7px 0px 5px" }}
                    className="fontAlmari"
                  />
                  {t("postuler")}
                </a>
              ) : null}
            </div>
          </Col>

          <Col xs="12" lg="12">
            <div
              className="dangerouslySetInnerHTML"
              dangerouslySetInnerHTML={{
                __html:
                  detailAvis.contenue == "null" ? null : detailAvis.contenue,
              }}
              style={{ padding: "30px" }}
            ></div>
          </Col>

          <Col xs="12" lg="12">
            {pieceJointes.map((o) => (
              <Row
                style={{
                  margin: "3px 10px 5px 10px",
                  borderBottom: "1px solid rgb(210, 207, 207)",
                  padding: "10px 10px 5px 10px",
                }}
              >
                <Col className="psj" xs="2" lg="2" style={{ margin: "auto" }}>
                  <img
                    src={`https://www.rimtic.com/images/icons/${o.extension}.png`}
                    alt=""
                    style={{ width: "32px", height: "auto" }}
                  />
                </Col>
                <Col className="psj" xs="10" lg="10">
                  <a
                    href={`https://www.rimtic.com/storage/documents/${o.nom}`}
                    target="_blank"
                    download
                    style={{
                      margin: "5px 7px 0px 7px",
                      fontWeight: "400",
                      color: "#227b1c",
                    }}
                  >
                    {o.nom}
                  </a>
                </Col>
              </Row>
            ))}
          </Col>

          <Partager
            url={`https://www.rimtic.com/rimtic-b/fr/share/emploi/${offreEmploi.id}`}
            created_at={offreEmploi.created_at}
          />

          <Col xs="12" lg="12">
            {offreEmploi.postule == 1 ? (
              <div style={{ margin: "10px 10px 5px 10px" }}>
                <a
                  href={`https://www.rimtic.com/cvtheque-f/postuler/${offreEmploi.id}`}
                  type="button"
                  className="btn btn-postuler fontAlmari"
                  style={{
                    background: "#047104",
                    color: "#fff",
                    width: "100%",
                    height: "100%",
                  }}
                >
                  <FontAwesomeIcon
                    icon={faFileAlt}
                    color="#fff"
                    size="xs"
                    style={{ padding: "0px 7px 0px 5px" }}
                    className="fontAlmari"
                  />
                  {t("postuler")}
                </a>
              </div>
            ) : null}
          </Col>
        </Row>
      )}
    </div>
  );
};

export default OffreEmploiDetail;
