import React, { useState, useEffect } from "react";
import axios from "../lib/axios";
import { useParams } from "react-router-dom";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Moment from "moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClock, faEye } from "@fortawesome/free-solid-svg-icons";
import { useTranslation } from "react-i18next";
import Spinner from "react-bootstrap/Spinner";
import Partager from "../components/partagerEtpublier";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet-async";

const AppelOffresDetail = () => {
  const [appelOffres, setAppelOffres] = useState({});
  const [societe, setSociete] = useState({});
  const [pieceJointes, setPieceJointes] = useState([]);
  const { slug, id } = useParams();
  const { t, i18n } = useTranslation();
  const [openW, setOpenW] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    let langDemande = "fr";
    if (window.location.href.indexOf("/ar/") > -1) {
      langDemande = "ar";
    }

    if (i18n.language != langDemande) {
      setLanguage();
      window.location.reload();
    }

    getAppelOffres();
  }, []);

  const setLanguage = () => {
    i18n.changeLanguage("fr");
    if (window.location.href.indexOf("/ar/") > -1) {
      i18n.changeLanguage("ar");
    }
  };

  const getAppelOffres = () => {
    setOpenW(true);
    axios
      .get("/api/appel_offres/" + slug + "/" + id, {
        headers: {
          Accept: "application/json",
        },
      })
      .then((response) => {
        if (response.data == "" || response.data.data.appelOffres.etat == 2) {
          navigate("/");
        } else {
          setAppelOffres(response.data.data.appelOffres);
          setSociete(response.data.data.appelOffres.societe);
          setPieceJointes(response.data.data.pieceJointes);
          setOpenW(false);
        }

        i18n.language == "fr"
          ? (document.title =
              t("appl_offres") +
              " - " +
              response.data.data.appelOffres.libelle +
              " | " +
              response.data.data.appelOffres.societe.nom)
          : (document.title =
              t("appl_offres") +
              " - " +
              response.data.data.appelOffres.libelle_ar +
              " | " +
              response.data.data.appelOffres.societe.nom);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  var detailAvis = {};

  i18n.language == "fr"
    ? (detailAvis = {
        id: appelOffres.id,
        libelle: appelOffres.libelle,
        contenue: appelOffres.contenue,
        ville: appelOffres.ville,
      })
    : (detailAvis = {
        id: appelOffres.id,
        libelle: appelOffres.libelle_ar,
        contenue: appelOffres.contenue_ar,
        ville: appelOffres.ville_ar,
      });

  return (
    <div style={{ padding: "30px" }}>
      <Helmet>
        <meta
          name="description"
          content={
            t("appl_offres") +
            " Mauritanie - " +
            appelOffres.libelle +
            " | " +
            societe.nom
          }
        />
      </Helmet>
      {openW == true ? (
        <div className="text-center">
          <Spinner animation="border" variant="danger" />
        </div>
      ) : (
        <Row>
          <Col xs="12" lg="12">
            <div
              className="info-soci-emp"
              style={{ paddingRight: "60px", padding: "2px" }}
            >
              <p className="fontAlmari" style={{ fontSize: "13px" }}>
                <FontAwesomeIcon icon={faClock} color="#255025" size="xs" />{" "}
                {t("limite")} :{" "}
                <span style={{ color: "red" }}>
                  {" "}
                  {Moment(appelOffres.fin).format("DD/MM/YYYY")}
                </span>
              </p>
              <p className="fontAlmari" style={{ fontSize: "13px" }}>
                <FontAwesomeIcon icon={faEye} color="#255025" size="xs" />{" "}
                <span style={{ color: "rgb(173, 8, 31)" }}>
                  {appelOffres.counter}
                </span>
              </p>
            </div>
          </Col>

          <Col xs="12" lg="12">
            <center>
              {societe.logo == null ? (
                <img
                  src="/images/offreRIMTIC.jpg"
                  alt={societe.nom}
                  style={{ maxWidth: "200px" }}
                />
              ) : (
                <img
                  className="image"
                  src={"https://www.rimtic.com/storage/images/" + societe.logo}
                  alt="Profile"
                  style={{ maxWidth: "200px" }}
                />
              )}
              <p
                className="fontAlmari"
                style={{
                  textAlign: "center",
                  fontWeight: "bold",
                  marginTop: "1px",
                }}
              >
                {societe.nom}
              </p>
            </center>
          </Col>

          <Col xs="12" lg="12">
            <div
              className="dangerouslySetInnerHTML fontAlmari"
              style={{ textAlign: "center", fontSize: "20px", margin: "10px" }}
              dangerouslySetInnerHTML={{
                __html:
                  detailAvis.libelle == "null" ? null : detailAvis.libelle,
              }}
            ></div>
          </Col>

          <Col xs="12" lg="12">
            <div
              className="dangerouslySetInnerHTML"
              style={{ padding: "10px 30px 20px 30px" }}
              dangerouslySetInnerHTML={{
                __html:
                  detailAvis.contenue != "<p>null</p>" &&
                  detailAvis.contenue != "null"
                    ? detailAvis.contenue
                    : null,
              }}
            ></div>
          </Col>

          <Col xs="12" lg="12">
            {pieceJointes.map((o, i) => (
              <Row
                key={i}
                style={{
                  margin: "3px 10px 5px 10px",
                  borderBottom: "1px solid rgb(210, 207, 207)",
                  padding: "10px 10px 5px 10px",
                  borderRadius: "10px",
                }}
              >
                <Col className="psj" xs="2" lg="2" style={{ margin: "auto" }}>
                  <img
                    src={`https://www.rimtic.com/images/icons/${o.extension}.png`}
                    alt="icon"
                    style={{ width: "32px" }}
                  />
                </Col>
                <Col className="psj" xs="10" lg="10">
                  <a
                    href={`https://www.rimtic.com/storage/documents/${o.nom}`}
                    target="_blank"
                    download
                    style={{
                      margin: "2px 7px 0px 7px",
                      fontWeight: "400",
                      color: "#227b1c",
                    }}
                  >
                    {o.nom}
                  </a>
                </Col>
              </Row>
            ))}
          </Col>

          <Partager
            url={`https://www.rimtic.com/rimtic-b/share/offre/${appelOffres.id}`}
            created_at={appelOffres.created_at}
          />
        </Row>
      )}
    </div>
  );
};

export default AppelOffresDetail;
