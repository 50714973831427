import React, {useEffect, useState} from 'react';
import EnteteAdmin from '../../components/componentAdmin/EnteteAdmin'
import Table from 'react-bootstrap/Table'; 
import { Link } from 'react-router-dom';
import Container from 'react-bootstrap/Container';
import axios from '../../lib/axios';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit } from '@fortawesome/free-solid-svg-icons';
import { Pagination } from '@mui/material/';
import Stack from '@mui/material/Stack';    


const AfficherOffresEmploi = () => {
    const [offresEmploi, setOffresEmploi] = useState([]);   
    const [pageNumber, setPageNumber] = useState(1);
    const [pagesNumber, setPagesNumber] = useState(1);    
    const [cherche, setCherche] = useState("");  

    // console.log(offresEmploi.filter(offre=>offre.libelle.toLowerCase().includes('Geo')))

    useEffect(() => {
        getOffresEmploi(1); 
    }, []);

    const paginate = (event, value) => {
        setPageNumber(value);
        getOffresEmploi(value);
    };

    const getOffresEmploi = (pn) => {

        axios.get('/api/tic/afficherOffresEmploi?page='+pn, {
            headers: {
                "Accept": "application/json"
            }
        }).then(response => { 
            console.log(response.data);
            setPagesNumber(response.data.offresEmploi.last_page)
            setOffresEmploi(response.data.offresEmploi.data)
            // setSociete(response.data.offresEmploi.societe) 
        }).catch(error => {
            console.log(error);
        });

    };

    // const chercherOffre = () => {
    //     const form = new FormData(); 
    //     form.append('libelle', libelle);
    //     form.append('numberOffre', numberOffre);

    //     axios.post('/api/chercherOffre', form, {
    //         headers: {
    //             "Accept": "application/json"
    //         }
    //     }).then(response => { 
    //         console.log(response.data);
    //         setOffresEmploi(response.data); 
    //     }).catch(error => {
    //         console.log(error);
    //     });

    // };

    
    
    return (
            <>
                <EnteteAdmin></EnteteAdmin> 
                <Container> 
                    {/* <br/>  
                    <div> 
                        <input type="text" onChange={(e) => setLibelle(e.target.value)} />
                        <button className='btn btn-primary' onClick={chercherOffre} style={{width:'150px', background:'#0d6efd', color:'#fff', margin:'0px 10px 0px 10px'}}>chercher Offre</button>
                    </div> */}

                    <br/>  
                    {/* <div> 
                        <input type="text" onChange={(e) => setCherche(e.target.value)} />
                     </div> */}
                    <br/>
                     <Table striped bordered hover >
                        <thead>
                            <tr> 
                                <th>#</th>
                                <th>Libelle</th>
                                <th>Societe</th>
                                <th>Date debut</th>
                                <th>Date fin</th>
                                <th>Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            {offresEmploi.map((o) => (
                            <tr key={o.id}> 
                                <td>{o.id}</td>
                                <td><Link to={`/tic/demploie/${o.id}`}>{o.libelle}</Link> </td>
                                <td>{o.societe.nom}</td>
                                <td> {o.debut} </td>
                                <td> {o.fin} </td>
                                <td> <Link to={`/tic/demploie/modifier/${o.id}`}><FontAwesomeIcon style={{color:'blue'}} icon={faEdit}/></Link></td>
                            </tr> 
                             ))} 
                        </tbody>
                    </Table>
                     <Stack alignItems="center" sx={{p:3}}>
                        <Pagination hideNextButton={true} hidePrevButton={true} sx={{ background:'#fff'}} count={pagesNumber} page={pageNumber} onChange={paginate} variant="outlined" color="primary" shape="rounded" />
                    </Stack> 
                </Container>
            </>

    );
};

export default AfficherOffresEmploi;