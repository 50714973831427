import React, { useEffect } from "react";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import NavDropdown from "react-bootstrap/NavDropdown";
import { Link } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "../../css/style_fr.css";
import { useNavigate } from "react-router-dom";
import axios from "../../lib/axios";
import { useTranslation } from "react-i18next";

const EnteteAdmin = () => {
  const ud = JSON.parse(window.sessionStorage.getItem("userData"));
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();

  useEffect(() => {
    if (ud != null && ud.user.role_id == 1) {
      console.log("ok");
    } else {
      navigate("/login");
    }
  }, []);

  const logout = (e) => {
    e.preventDefault();
    axios
      .post("/api/logout", {
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + ud.token,
        },
      })
      .then((response) => {
        window.sessionStorage.removeItem("userData");
        navigate("/");
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <div>
      <header>
        <Row style={{ margin: "auto" }}>
          <Col xs={12} sm={4}></Col>
          <Col xs={12} sm={4} className="img-in-nav">
            <Link to="/">
              <img
                style={{ width: "70%" }}
                src="https://link.mr/rimtic-f/images/logoRIMTIC.png"
              />
            </Link>
          </Col>
          <Col xs={12} sm={4}></Col>
        </Row>

        <Navbar bg="white" expand="lg" style={{ height: "60px" }}>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="m-auto">
              <Nav.Link className="link-nav-admin" as={Link} to="/tic">
                Accueil Admin
              </Nav.Link>

              <Nav.Link
                className="link-nav-admin"
                href="https://rimtic.com/cvtheque-f/ll/afficherCVs"
              >
                Afficher les CVs
              </Nav.Link>

              <Nav.Link
                className="link-nav-admin"
                as={Link}
                to="/tic/afficherOffresEmploi"
              >
                Afficher les offres d'emplois
              </Nav.Link>

              <Nav.Link
                className="link-nav-admin"
                as={Link}
                to="/tic/afficherAppelsOffres"
              >
                Afficher les appels d'offres
              </Nav.Link>

              <Nav.Link
                className="link-nav-admin"
                as={Link}
                to="/tic/afficherTousAvis"
              >
                Afficher les avis
              </Nav.Link>

              <Nav.Link
                className="link-nav-admin"
                as={Link}
                to="/tic/afficherSocietes"
              >
                Afficher les Societes
              </Nav.Link>

              {ud != null ? (
                <NavDropdown title={ud.user.name} id="e-nav">
                  <NavDropdown.Item eventKey="1" onClick={logout}>
                    Logout
                  </NavDropdown.Item>
                </NavDropdown>
              ) : (
                ""
              )}
            </Nav>
          </Navbar.Collapse>
        </Navbar>
      </header>
    </div>
  );
};

export default EnteteAdmin;
