import React, {useEffect, useState} from 'react';
import { Container, Row, Col, Form, Button } from 'react-bootstrap';
import EnteteAdmin from '../../components/componentAdmin/EnteteAdmin'
import { useParams, useNavigate } from 'react-router-dom';
import axios from '../../lib/axios';


const EditeSociete = () => {
    const [societe, setSociete] = useState({});
    const [idSociete, setIdSociete] = useState(0);
    const [nomSociete, setNomSociete] = useState('');
    const [logoSociete, setLogoSociete] = useState('');
    const [newLogoSociete, setNewLogoSociete] = useState('');
    const [adresseSocietes, setAdressSociete] = useState('');
    const {id} = useParams();
    const navigate = useNavigate();

    useEffect(() => {
        getSociete();  
    }, []);

    const getSociete = () => { 
        axios.get(`/api/tic/dsociete/modifier/${id}`, {
            headers: {
                "Accept": "application/json"
            }
        }).then(response => { 
            setSociete(response.data.societe)
            setIdSociete(response.data.societe.id)
            setNomSociete(response.data.societe.nom)
            setLogoSociete(response.data.societe.logo)
            setAdressSociete(response.data.societe.adresse) 
        }).catch(error => {
            console.log(error); 
        });

    };

    const modifierSociete = (e) => {
        
        const form = new FormData();  
        form.append('idSociete', idSociete);
        form.append('nomSociete', nomSociete);
        form.append('adresse', adresseSocietes);
        form.append('logo', newLogoSociete);  

        axios.post(`/api/tic/dsociete/modifier/${id}`, form, {
            headers: {
                'Accept': 'application/json', 
            }
        }).then(response => {  
            console.log(response.data);
          navigate(`/tic/desociete/${id}`)   
        }).catch(error => {
            console.log(error)    
        });
      
    };
    return (
            <>
            <EnteteAdmin></EnteteAdmin>
            <Container>
 
                <div>
                    <p style={{border:'solid 2px', borderLeft:'0px', borderRight:'0px', borderColor:'black', textAlign:'center', fontSize:'25px', marginTop:'10px'}}>MODIFIER OFFRE D'EMPLOI</p>
                </div> 

                <div>  
                    <Row style={{marginTop:"15px", background:'#EEE', margin:'15px 1px 0px 1px', paddingBottom:'10px'}}>
                        <input type="text" value={idSociete} hidden />
                        <Col xs={12} sm={4}>  
                            <Form.Label>Société</Form.Label> 
                            <Form.Control type="text" value={nomSociete} onChange={(e) => {setNomSociete(e.target.value)}}/>
   
                        </Col>

                        <Col xs={12} sm={4}>
                            <Form.Label>Logo</Form.Label> 
                            <img src={'https://www.rimtic.com/storage/images/'+ logoSociete} style={{height:'40px', width:'70px'}}/> 
                            <Form.Control type="file"  onChange={(e) => {setNewLogoSociete(e.target.files[0])}} />

                        </Col>

                        <Col xs={12} sm={4}>
                            <Form.Label>Adresse</Form.Label>
                            <Form.Control type="text" value={adresseSocietes} onChange={(e) => {setAdressSociete(e.target.value)}}/> 
                        </Col>
                    </Row>  

                </div> 

                <div style={{textAlign:'center', marginTop:'20px'}}> 
                        <Button className='btn btn-success' onClick={modifierSociete} style={{width:'190px', background:'green', color:"#fff"}}>Valider</Button>
                </div> 

            </Container>      
            
            </>

    );
};

export default EditeSociete;