import React, { useState, useEffect } from "react";
import axios from "../lib/axios";
import { Col, Container, Row } from "react-bootstrap";
import { t } from "i18next";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

const Article = () => {
  const { id } = useParams();
  const [article, setArticle] = useState({});
  const [pieceJointes, setPieceJointes] = useState([]);
  const { t, i18n } = useTranslation();

  useEffect(() => {
    getArticle();
    document.title = "RIMTIC" + " - " + t("hr_solutions");
  }, []);

  const getArticle = () => {
    axios
      .get("/api/article/" + id, {
        headers: {
          Accept: "application/json",
        },
      })
      .then((response) => {
        setPieceJointes(response.data.data.pieceJointes);

        var articleP = {};
        i18n.language == "fr"
          ? (articleP = {
              id: response.data.data.article.id,
              libelle: response.data.data.article.libelle_fr,
              contenue: response.data.data.article.contenue_fr,
            })
          : (articleP = {
              id: response.data.data.article.id,
              libelle: response.data.data.article.libelle_ar,
              contenue: response.data.data.article.contenue_ar,
            });
        setArticle(articleP);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <Container style={{ marginTop: "20px" }}>
      <div
        className="row"
        style={{ textAlign: "center", marginBottom: "10px" }}
        dangerouslySetInnerHTML={{ __html: article.libelle }}
      ></div>
      <div
        className="row"
        dangerouslySetInnerHTML={{ __html: article.contenue }}
      ></div>
      {pieceJointes.map((o) => (
        <Row
          style={{
            margin: "3px 10px 5px 10px",
            borderBottom: "1px solid rgb(210, 207, 207)",
            padding: "10px 10px 5px 10px",
            borderRadius: "10px",
          }}
        >
          <Col className="psj" xs="2" lg="2" style={{ margin: "auto" }}>
            <img
              src={`https://www.rimtic.com/images/icons/${o.extension}.png`}
              alt=""
              style={{ width: "32px" }}
            />
          </Col>
          <Col className="psj" xs="10" lg="10">
            <a
              href={`https://www.rimtic.com/storage/documents/${o.nom}`}
              target="_blank"
              download
              style={{
                margin: "2px 7px 0px 7px",
                fontWeight: "400",
                color: "#227b1c",
              }}
            >
              {o.libelle}
            </a>
          </Col>
        </Row>
      ))}
    </Container>
  );
};

export default Article;
