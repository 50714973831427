import React, { useState, useEffect } from 'react';
import axios from '../../lib/axios';
import { useParams, useNavigate } from 'react-router-dom'; 
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Moment from 'moment';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"; 
import Table from 'react-bootstrap/Table';
import Button from 'react-bootstrap/Button';
import Swal from 'sweetalert2'; 
import EnteteAdmin from '../../components/componentAdmin/EnteteAdmin'
import { useTranslation } from 'react-i18next';

const AdminAfficherDetailAppeleOffer = () => {
    const { t, i18n } = useTranslation();

    const [appelOffres, setAppelOffres] = useState({});
    const [societe, setSociete] = useState({});
    const [pieceJointes, setPieceJointes] = useState([]);
    const {slug, id} = useParams();
    const [type, setType] = useState('appelOffres');
    const navigate = useNavigate();
    const ud = JSON.parse(window.sessionStorage.getItem('userData'));

    

    useEffect(() => {
        if(ud != null && ud.user.role_id ==1 ){ 
            getAppelOffres(); 
        }
        else{
            navigate('/login')
        }
    }, []);

    const getAppelOffres = () => {

        axios.get('/api/tic/doffre/'+ id, {
            headers: {
                "Accept": "application/json"
            }
        }).then(response => { 
            setAppelOffres(response.data.aplOffer); 
            setSociete(response.data.aplOffer.societe);
            setPieceJointes(response.data.pieceJointes); 
            console.log(response.data)
        }).catch(error => {
            console.log(error);
        });

    };

    var detailAvis  = {}

    i18n.language == 'fr' ? 
        detailAvis = {
        'id' : appelOffres.id, 
        'libelle' : appelOffres.libelle, 
        'contenue' : appelOffres.contenue, 
        } 
        :  
        detailAvis = {
        'id' : appelOffres.id,
        'libelle' : appelOffres.libelle_ar, 
        'contenue' : appelOffres.contenue_ar,
        }


    const validerAppleOffre = () => { 
        Swal.fire({
            title: 'Valider cet Apple Offre ?',
            showDenyButton: true, 
            confirmButtonText: 'Yes',
            denyButtonText: 'No',
            customClass: {
                actions: 'my-actions',
                cancelButton: 'order-1 right-gap',
                confirmButton: 'order-2',
                denyButton: 'order-3',
            }
            }).then((result) => {
            if (result.isConfirmed) { 
                axios.post(`/api/validerAppelOffre/${id}`, {
                headers: {
                    'Accept': 'application/json', 
                }
                }).then(response => {  
                Swal.fire(response.data.validation, '', 'success')  
                }).catch(error => {
                    console.log(error)    
                });
            } else if (result.isDenied) {
                Swal.fire('Validation annuler', '', 'info')
            }
        })  
     

    }; 

    const desactiverAppelOffre = () => { 
        Swal.fire({
            title: 'desactiver cet Apple Offre ?',
            showDenyButton: true, 
            confirmButtonText: 'Yes',
            denyButtonText: 'No',
            customClass: {
                actions: 'my-actions',
                cancelButton: 'order-1 right-gap',
                confirmButton: 'order-2',
                denyButton: 'order-3',
            }
            }).then((result) => {
            if (result.isConfirmed) { 
                axios.post(`/api/desactiverAppelOffre/${id}`, {
                headers: {
                    'Accept': 'application/json', 
                }
                }).then(response => {  
                Swal.fire(response.data.validation, '', 'success')  
                }).catch(error => {
                    console.log(error)    
                });
            } else if (result.isDenied) {
                Swal.fire('Validation annuler', '', 'info')
            }
        })  
     

    }; 

    const annulerAppleOffre = () => { 
        Swal.fire({
            title: 'Annuler cet Apple Offre ?',
            showDenyButton: true, 
            confirmButtonText: 'Yes',
            denyButtonText: 'No',
            customClass: {
                actions: 'my-actions',
                cancelButton: 'order-1 right-gap',
                confirmButton: 'order-2',
                denyButton: 'order-3',
            }
            }).then((result) => {
            if (result.isConfirmed) {

                axios.post(`api/tic/doffre/annuler/${id}/${type}`, {
                headers: {
                    'Accept': 'application/json', 
                }
                }).then(response => {  
                Swal.fire(response.data.validation, '', 'success')
                navigate('/tic')  
                
                }).catch(error => {
                    console.log(error)    
                });

                
            } else if (result.isDenied) {
                Swal.fire('Validation annuler', '', 'info')
            }
        })  
     

    };

    const retournerAppleOffre = () => { 
        Swal.fire({
            title: t('retourner_offre_emp'),
            showDenyButton: true, 
            confirmButtonText: t('oui'),
            denyButtonText: t('non'),
            customClass: {
                actions: 'my-actions',
                cancelButton: 'order-1 right-gap',
                confirmButton: 'order-2',
                denyButton: 'order-3',
            }
            }).then((result) => {
            if (result.isConfirmed) {

                axios.post(`api/tic/doffre/retourne/${id}/${type}`, {
                headers: {
                    'Accept': 'application/json', 
                }
                }).then(response => { 
                console.log(response.data)
                Swal.fire(response.data.validation, '', 'success')
                navigate('/tic')
                //   Swal.fire({icon: "success", text: response.data.validation,})  
                
                }).catch(error => {
                    console.log(error)    
                });

                
            } else if (result.isDenied) {
                Swal.fire(t('Validation_annuler'), '', 'info')
            }
        }) 
    } 

    const mettreAppelOffreAlaune = () => { 
        Swal.fire({
            title: 'mettre cet offre ala une  ?',
            showDenyButton: true, 
            confirmButtonText: 'Yes',
            denyButtonText: 'No',
            customClass: {
                actions: 'my-actions',
                cancelButton: 'order-1 right-gap',
                confirmButton: 'order-2',
                denyButton: 'order-3',
            }
            }).then((result) => {
            if (result.isConfirmed) {

                axios.post(`api/tic/mettreAppelOffreAlaune/${id}`, {
                headers: {
                    'Accept': 'application/json', 
                }
                }).then(response => { 
                console.log(response.data)
                Swal.fire(response.data.validation, '', 'success')
                //   Swal.fire({icon: "success", text: response.data.validation,})  
                
                }).catch(error => {
                    console.log(error)    
                });

                
            } else if (result.isDenied) {
                Swal.fire('Validation annuler', '', 'info')
            }
        })  
     

    };

    const supprimerAppelOffreAlaune = () => { 
        Swal.fire({
            title: 'suppri er cet offre ala une ?',
            showDenyButton: true, 
            confirmButtonText: 'Yes',
            denyButtonText: 'No',
            customClass: {
                actions: 'my-actions',
                cancelButton: 'order-1 right-gap',
                confirmButton: 'order-2',
                denyButton: 'order-3',
            }
            }).then((result) => {
            if (result.isConfirmed) {

                axios.post(`api/tic/mettreAppelOffreAlaune/${id}`, {
                headers: {
                    'Accept': 'application/json', 
                }
                }).then(response => { 
                console.log(response.data)
                Swal.fire(response.data.validation, '', 'success')
                //   Swal.fire({icon: "success", text: response.data.validation,})  
                
                }).catch(error => {
                    console.log(error)    
                });

                
            } else if (result.isDenied) {
                Swal.fire('supprition annuler', '', 'info')
            }
        })  
     

    };

    

    return (
        <>
            <EnteteAdmin></EnteteAdmin>  
            <div style={{padding:'30px'}}>   
                <Row>
 
                    <Col xs="12" lg="12">
                        <div className='info-soci-emp' style={{paddingRight:'60px', padding:'2px'}}> 
                            <p><FontAwesomeIcon icon="clock" color="#255025" size="xs" /> {appelOffres.fin}</p>
                            <p><FontAwesomeIcon icon="eye" color="#255025" size="xs" /> {appelOffres.counter}</p>
                        </div>
                    </Col> 


                    <Col xs="12" lg="12">
                        <center> 
                            {societe.logo == null ? <img src="/images/offreRIMTIC.jpg" alt=""  style={{maxWidth:'200px'}}/> : 
                            <img className='image' src={'https://www.rimtic.com/storage/images/' + societe.logo} alt='Profile' style={{maxWidth:'200px'}} />
                        }
                            <p className='fontAlmari' style={{textAlign:'center',  fontWeight:'bold',marginTop:'1px' }}>{societe.nom}</p>
                        </center>
                    </Col>

                    <Col xs="12" lg="12" >
                        <p className='fontAlmari' style={{textAlign:'center', fontSize:'20px',margin:'10px' }}>{detailAvis.libelle != 'null' ? detailAvis.libelle : null}</p>
                    </Col> 

                    

                    <Col xs="12" lg="12" >
                        <div className='fontAlmari' style={{padding:'10px 30px 20px 30px'}} dangerouslySetInnerHTML={{__html: detailAvis.contenue != '<p>null</p>' && detailAvis.contenue != 'null' ? detailAvis.contenue : null}} ></div>
                    </Col>

                    <Table striped bordered hover size="sm" style={{width:'92%', margin:'2px 0px 0px 40px'}}> 
                        <tbody>
                            {pieceJointes.map((o) => ( 
                            <tr>
                                <td><img src="https://www.rimtic.com/images/icons/docx.png" alt="" style={{width:'32px'}} /></td>
                                <td><a href={`https://www.rimtic.com/storage/documents/${o.nom}`} download target="_blank" style={{width:'98%'}}>{o.nom}</a></td> 
                            </tr> 
                            ))}
                            
                        </tbody>
                    </Table>

                     
                </Row> 
            </div>

            <Row style={{paddingLeft:'40px', paddingRight:'40px'}}>
                {appelOffres.etat == 0 ?
                 <Col xs={12} sm={4} className='btn-deposer-in-accueil'><Button onClick={validerAppleOffre} style={{background:'green', color:'#fff'}}> Activer </Button> </Col>
                 :
                 <Col xs={12} sm={4} className='btn-deposer-in-accueil'><Button onClick={desactiverAppelOffre} style={{background:'green', color:'#fff'}}> Desactiver </Button> </Col>
                 
                } 
                {appelOffres.etat != 2 ?
                <Col xs={12} sm={4} className='btn-deposer-in-accueil'><Button onClick={annulerAppleOffre} style={{background:'red', color:'#fff'}}> Annuler </Button></Col>
                : appelOffres.etat == 2 ?
                <Col xs={12} sm={4} className='btn-deposer-in-accueil'><Button onClick={retournerAppleOffre} style={{background:'red', color:'#fff'}}> Retoure </Button></Col>
                : ''
                }
                {appelOffres.alaune == 0 ?
                <Col xs={12} sm={4} className='btn-deposer-in-accueil'><Button onClick={mettreAppelOffreAlaune} style={{background:'blue', color:'#fff'}}> Mettre a l'une  </Button></Col>
                : appelOffres.alaune == 1 ?
                <Col xs={12} sm={4} className='btn-deposer-in-accueil'><Button onClick={supprimerAppelOffreAlaune} style={{background:'blue', color:'#fff'}}> Supprimer a l'une  </Button></Col>
                :''
                }
            </Row>

            <div style={{margin:'30px', padding:'7px', border:'1px solid black', borderRadius:'5px'}}>  
                <Row style={{margin:'5px'}}>
                    <Col xs="12" lg="6">
                        <span style={{fontWeight:'bold'}}>Partager l'annonce avec vos amis:</span>
                    </Col>

                    <Col xs="12" lg="6" style={{textAlign:'end'}}>
                        <span style={{fontWeight:'bold'}}>Publié le : </span> {Moment(appelOffres.created_at).format('DD MMM YYYY')}
                    </Col> 
                </Row>
            </div>
        </>
    );
};

export default AdminAfficherDetailAppeleOffer;