import React from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { format } from "date-fns";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEye,
  faMapMarkerAlt,
  faClock,
  faBuilding,
} from "@fortawesome/free-solid-svg-icons";

import { useTranslation } from "react-i18next";

const AppelOffres = (props) => {
  const { t, i18n } = useTranslation();

  var detailAvis = {};

  i18n.language == "fr"
    ? (detailAvis = {
        id: props.avis.id,
        libelle: props.avis.libelle,
        ville: props.avis.ville,
      })
    : (detailAvis = {
        id: props.avis.id,
        libelle: props.avis.libelle_ar,
        ville: props.avis.ville_ar,
      });

  const libelle = () => ({ __html: detailAvis.libelle });
  return (
    <Row className="wrap" style={{ minHeight: "130px" }}>
      <Col xs="2" lg="2" className="col2">
        <a
          href={`/${i18n.language}/appel-offres/${props.avis.slug}/${props.avis.id}`}
        >
          <img
            loading="lazy"
            src={"https://rimtic.com/storage/images/" + props.avis.societe.logo}
            style={{ height: "100%", width: "100%" }}
            alt={props.avis.societe.nom}
          />
        </a>
      </Col>

      <Col xs="10" lg="10">
        <Row>
          <Col xs="12" lg="12">
            <Link
              to={`/${i18n.language}/appel-offres/${props.avis.slug}/${props.avis.id}`}
              style={{ borderBottom: "1px solid #eee " }}
            >
              <p
                style={{ color: "black", fontSize: "0.89em" }}
                className="fontAlmari"
                dangerouslySetInnerHTML={libelle()}
              ></p>
            </Link>
          </Col>

          <Col xs="12" lg="12" style={{ display: "flex" }}>
            <p className="societe col-6 col-sm-6">
              <FontAwesomeIcon
                icon={faMapMarkerAlt}
                style={{ marginRight: 2, color: "#255025" }}
                size="xs"
              />
              {detailAvis.ville}
            </p>

            <p className="col-6 col-sm-6" style={{ textAlign: "end" }}>
              <span className="societe" style={{ padding: "2px" }}>
                <FontAwesomeIcon
                  icon={faEye}
                  style={{ marginRight: 2, color: "#255025" }}
                  size="xs"
                />{" "}
                {props.avis.counter}
              </span>
              <span
                className="societe"
                style={{
                  padding: "2px",
                  color: "rgb(173, 8, 31)",
                  textAlign: "right",
                }}
              >
                <FontAwesomeIcon
                  icon={faClock}
                  size="xs"
                  spin
                  style={{ marginRight: 2 }}
                />
                {format(new Date(props.avis.fin), "dd/MM/yyyy")}
              </span>
            </p>
          </Col>

          <Col xs="12" lg="12">
            <p
              className="libelle-appleoffre"
              style={{ textAlign: "justify", fontSize: "12px" }}
            >
              <FontAwesomeIcon
                icon={faBuilding}
                size="xs"
                style={{ marginRight: 2 }}
              />
              {props.avis.societe.nom}
            </p>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default AppelOffres;
