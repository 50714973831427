import React from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Moment from "moment";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClock, faEye, faBuilding } from "@fortawesome/free-solid-svg-icons";

import { useTranslation } from "react-i18next";

const Avis = (props) => {
  const { t, i18n } = useTranslation();

  var detailAvis = {};

  i18n.language == "fr"
    ? (detailAvis = {
        id: props.avis.id,
        libelle: props.avis.libelle,
        ville: props.avis.ville,
      })
    : (detailAvis = {
        id: props.avis.id,
        libelle: props.avis.libelle_ar,
        ville: props.avis.ville_ar,
      });
  return (
    <Row className="wrap" style={{ minHeight: "130px" }}>
      <Col xs="3" lg="3">
        <a href={`/${i18n.language}/avis/${props.avis.slug}/${props.avis.id}`}>
          <img
            loading="lazy"
            src={"https://rimtic.com/storage/images/" + props.avis.societe.logo}
            title={props.avis.societe.nom}
            alt={props.avis.societe.nom}
            style={{ width: "82px", height: "100px", padding: "2px" }}
          />
        </a>
      </Col>
      <Col xs="9" lg="9">
        <Row>
          <Col xs="12" lg="12">
            <Link
              to={`/${i18n.language}/avis/${props.avis.slug}/${props.avis.id}`}
              style={{ borderBottom: "1px solid #eee", fontSize: "0.89em" }}
              className="fontAlmari"
            >
              {/* {detailAvis.libelle}  */}
              <div
                className="dangerouslySetInnerHTML"
                dangerouslySetInnerHTML={{
                  __html:
                    detailAvis.libelle == "null" ? null : detailAvis.libelle,
                }}
              ></div>
            </Link>
          </Col>

          <Col
            xs="12"
            lg="12"
            style={{ display: "flex", justifyContent: "end" }}
          >
            <p className="societe" style={{ padding: "2px" }}>
              <FontAwesomeIcon icon={faEye} color="#255025" size="xs" />{" "}
              {props.avis.counter}
            </p>
            <p className="societe" style={{ padding: "2px" }}>
              <i style={{ color: "rgb(173, 8, 31)", paddingLeft: "10px" }}>
                <FontAwesomeIcon icon={faClock} size="xs" spin />{" "}
                {Moment(props.avis.fin).format("DD/MM/YYYY")}
              </i>
            </p>
          </Col>
          <Col xs="12" lg="12">
            <p style={{ fontSize: "12px" }}>
              <FontAwesomeIcon
                icon={faBuilding}
                size="xs"
                style={{ marginRight: 2 }}
              />
              {props.avis.societe.nom}
            </p>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default Avis;
