import React, {useState, useEffect } from 'react';
import { Container } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';  
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';   
import axios from '../../lib/axios';
import { useParams, useNavigate } from 'react-router-dom';
import EnteteAdmin from '../../components/componentAdmin/EnteteAdmin'
import { useTranslation } from 'react-i18next';
import { CKEditor } from 'ckeditor4-react';

const TraductionAppelOffre = () => {
 
    const [applelOffre, setApplelOffre] = useState({}); 
    const [idAppelOffres, setIdAppelOffres] = useState(0);
    const [adresse, setAdresse] = useState(null);
    const [adresseAr, setAdresseAr] = useState(null);
    const [libelle, setLibelle] = useState(null);
    const [libelleAr, setLibelleAr] = useState(null); 
    const [contenue, setContenue] = useState(null);
    const [contenueAr, setContenueAr] = useState(null);
    
    const {id} = useParams();
    const navigate = useNavigate();
    const { t, i18n } = useTranslation();
    const ud = JSON.parse(window.sessionStorage.getItem('userData'));
 
    useEffect(() => { 
        if(ud != null && ud.user.role_id == 2 ){ 
            getAppelOffre(); 
        }
        else{
            navigate('/login')
        }
         
    }, []);

    

    const getAppelOffre = () => { 
        axios.get(`/api/tic/doffre/modifier/${id}`, {
            headers: {
                "Accept": "application/json"
            }
        }).then(response => {  
            setApplelOffre(response.data.aplOffer)
            setIdAppelOffres(response.data.aplOffer.id)
            setAdresse(response.data.aplOffer.ville)
            setAdresseAr(response.data.aplOffer.ville_ar)
            setLibelle(response.data.aplOffer.libelle)
            setLibelleAr(response.data.aplOffer.libelle_ar) 
            setContenue(response.data.aplOffer.contenue)
            setContenueAr(response.data.aplOffer.contenue_ar) 
        }).catch(error => {
            console.log(error); 
        });

    };

    const modifierAppleOffre = (e) => { 
        const form = new FormData(); 
        form.append('idAppelOffres', idAppelOffres); 
        form.append('adresse', adresse);
        form.append('adresse_ar', adresseAr);
        form.append('libelle', libelle);
        form.append('libelle_ar', libelleAr); 
        if(contenue != null){ 
            form.append('contenue', contenue.printHeaderText);
        }
        if(contenueAr != null){ 
            form.append('contenue_ar', contenueAr.printHeaderTextAr);
        }
        
        axios.post(`/api/traduireAppelOffres`, form, {
            headers: {
                'Accept': 'application/json', 
            }
        }).then(response => {  
          navigate(`/trad`);
         }).catch(error => {
            console.log(error)    
        });
      
    }; 

    

    const changeContenueText = (value) => { 
        setContenue((prev)=>{
            return {
                ...prev,
                printHeaderText: value.editor.getData()
            }
        }) 
    }
    
    const changeContenueTextAr = (value) => { 
        setContenueAr((prev)=>{
            return {
                ...prev,
                printHeaderTextAr: value.editor.getData()
            }
        }) 
    }


    let editorToolbar = [
        { name: "tools", items: ["Maximize"] },
        {
            name: "clipboard",
            items: ["Cut", "Copy", "Paste", "PasteText", "-", "Undo", "Redo"]
        },
        { name: "links", items: ["Link", "Unlink"] },
        { name: "document", items: ["Source"] }, 
        {
            name: "basicstyles",
            items: [
            "Bold",
            "Italic",
            "Underline",
            "Strike",
            "-",
            "Subscript",
            "Superscript"
            ]
        },
        {
            name: "paragraph",
            items: [
            "NumberedList",
            "BulletedList",
            "-",
            "Outdent",
            "Indent",
            "Blockquote"
            ]
        },
        {
            name: "align",
            items: [
            "AlignLeft",
            "JustifyLeft",
            "JustifyCenter",
            "JustifyRight",
            "JustifyBlock"
            ]
        }, 
        { name: "styles", items: ["Format", "-", "Font", "-", "FontSize"] },
        { name: "colors", items: ["TextColor", "BGColor"] },
        { name: "insert", items: ["Image", "Table", "HorizontalRule"] },
        "/"
        
    ]

    return (
        <>
            <Container>

                <div>
                    <p className='fontAlmari' style={{border:'solid 2px', borderLeft:'0px', borderRight:'0px', borderColor:'black', textAlign:'center', fontSize:'25px', marginTop:'10px', padding:'10px'}}>{t('trad_apple_off')}</p>
                </div> 

                <div> 

                    <Row style={{margin:'10px 0px 5px 0px'}}>
                       <input type="text" hidden value={idAppelOffres} />
                        
                        <Col xs={12} sm={6}>
                            <Form.Label className='fontAlmari'>المدينة</Form.Label>  
                            <Form.Control type="text" value={adresseAr} onChange={(e) => setAdresseAr(e.target.value)} /> 
                           
                        </Col>

                        <Col xs={12} sm={6}> 
                            <Form.Label className='fontAlmari'>Ville</Form.Label> 
                            <Form.Control type="text" value={adresse} onChange={(e) => setAdresse(e.target.value)} disabled/> 
                           
                        </Col>

                        <Col xs={12} sm={6}>  
                            <Form.Label className='fontAlmari'>العنوان</Form.Label> 
                            <Form.Control type="text" value={libelleAr} onChange={(e) => setLibelleAr(e.target.value)} /> 
                            
                        </Col>

                        <Col xs={12} sm={6}>  
                            <Form.Label className='fontAlmari'>Titre</Form.Label>
                            <Form.Control type="text" value={libelle} onChange={(e) => setLibelle(e.target.value)} disabled/> 
                            
                        </Col>

                        
                        <Col xs={12} sm={12} style={{marginTop:'10px'}}> 
                        <Form.Label className='fontAlmari'>المحتوي</Form.Label>
                            {
                                 applelOffre.contenue_ar && <CKEditor   
                                    config={{toolbar: editorToolbar,
                                    extraPlugins: "colorbutton,colordialog,font,justify",
                                    removeButtons: ""}} 
                                    initData={applelOffre.contenue_ar} 
                                    onChange={changeContenueTextAr}
                                /> 

                            }
                            {
                                !applelOffre.contenue_ar && <CKEditor   
                                    config={{toolbar: editorToolbar,
                                    extraPlugins: "colorbutton,colordialog,font,justify",
                                    removeButtons: ""}}   
                                    onChange={changeContenueTextAr}
                                /> 
                            }
                        </Col>
                    </Row> 

                    <div style={{textAlign:'center'}}> 
                        <Button className='btn btn-success fontAlmari' onClick={modifierAppleOffre} style={{width:'190px', background:'green', color:"#fff", marginTop:'10px'}}>{t('traduire')}</Button> 
                    </div>

                </div> 
                
            </Container>  
        </>

    );
};

export default TraductionAppelOffre;