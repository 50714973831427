import React, { useEffect, useState } from "react";
import Accordion from "react-bootstrap/Accordion";
import Container from "react-bootstrap/Container";
import Table from "react-bootstrap/Table";
import axios from "../../lib/axios";
import { Link } from "react-router-dom";
import EnteteAdmin from "../../components/componentAdmin/EnteteAdmin";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit } from "@fortawesome/free-solid-svg-icons";

const Admin = () => {
  const { t, i18n } = useTranslation();
  const [offresEmploi, setOffresEmploi] = useState([]);
  const [appelOffres, setAppelOffres] = useState([]);
  const [avis, setAvis] = useState([]);
  const navigate = useNavigate();
  const ud = JSON.parse(window.sessionStorage.getItem("userData"));

  useEffect(() => {
    if (ud != null && ud.user.role_id == 1) {
      getOffres();
    } else {
      navigate("/login");
    }
  }, []);

  const getOffres = () => {
    axios
      .get("/api/tic", {
        headers: {
          Accept: "application/json",
        },
      })
      .then((response) => {
        setOffresEmploi(response.data.offerEmploies);
        setAppelOffres(response.data.appeleOffers);
        setAvis(response.data.avis);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <>
      <EnteteAdmin></EnteteAdmin>

      <Container>
        <br />
        <Tabs
          className="tabs-in-accueil fontAlmari"
          id="controlled-tab-example"
          justify
        >
          <Tab
            className="offresEmploi tab-inaccueil p-1 fontAlmari"
            eventKey="OffresEmploi"
            title={t("offre_emploi")}
          >
            {offresEmploi.length != 0 ? (
              <Table striped bordered hover>
                <thead>
                  <tr>
                    <th className="fontAlmari">{t("societe")}</th>
                    <th className="fontAlmari">{t("titre")}</th>
                    <th className="fontAlmari">Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {offresEmploi.map((o) => (
                    <tr>
                      <td>
                        <Link to={`/tic/demploie/${o.id}`}>
                          {o.societe.nom}
                        </Link>
                      </td>
                      {i18n.language == "fr" ? (
                        <td>
                          <Link to={`/tic/demploie/${o.id}`}>{o.libelle}</Link>
                        </td>
                      ) : (
                        <td>
                          <Link to={`/tic/demploie/${o.id}`}>
                            {o.libelle_ar}
                          </Link>
                        </td>
                      )}
                      <td>
                        <Link to={`/tic/demploie/modifier/${o.id}`}>
                          <FontAwesomeIcon icon={faEdit} />{" "}
                        </Link>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            ) : null}
          </Tab>

          <Tab
            className="AppelsOffres tab-inaccueil p-1 fontAlmari"
            eventKey="AppelsOffres"
            title={t("apple_offre")}
          >
            {appelOffres.length != 0 ? (
              <Table striped bordered hover>
                <thead>
                  <tr>
                    <th className="fontAlmari">{t("societe")}</th>
                    <th className="fontAlmari">{t("titre")}</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {appelOffres.map((o) => (
                    <tr>
                      <td>
                        <Link to={`/tic/doffre/${o.id}`}>{o.societe.nom}</Link>
                      </td>
                      {i18n.language == "fr" ? (
                        <td>
                          <Link to={`/tic/doffre/${o.id}`}>{o.libelle}</Link>
                        </td>
                      ) : (
                        <td>
                          <Link to={`/tic/doffre/${o.id}`}>{o.libelle_ar}</Link>
                        </td>
                      )}
                      <td>
                        <Link to={`/tic/doffre/modifier/${o.id}`}>
                          <FontAwesomeIcon icon={faEdit} />
                        </Link>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            ) : null}
          </Tab>

          <Tab
            className="Pub tab-inaccueil p-1 fontAlmari"
            eventKey="Avis"
            title={t("avis")}
          >
            {avis.length != 0 ? (
              <Table striped bordered hover>
                <thead>
                  <tr>
                    <th className="fontAlmari">{t("societe")}</th>
                    <th className="fontAlmari">{t("titre")}</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {avis?.map((a) => (
                    <tr>
                      <td>
                        <Link to={`/tic/davis/${a.id}`}>{a.societe.nom}</Link>
                      </td>
                      {i18n.language == "fr" ? (
                        <td>
                          <Link to={`/tic/davis/${a.id}`}>{a.libelle}</Link>
                        </td>
                      ) : (
                        <td>
                          <Link to={`/tic/davis/${a.id}`}>{a.libelle_ar}</Link>
                        </td>
                      )}
                      <td>
                        {" "}
                        <Link to={`/tic/davis/modifier/${a.id}`}>
                          <FontAwesomeIcon icon={faEdit} />
                        </Link>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            ) : null}
          </Tab>
        </Tabs>
      </Container>
    </>
  );
};

export default Admin;
