import React, {useState, useEffect } from 'react';
import axios from '../lib/axios';
import { Container } from 'react-bootstrap';
import { t } from 'i18next';

const ModelesCV = () => {
    const [openW, setOpenW] = useState(false);
    const [modelesCv, setModelesCv] = useState([]);

    useEffect(() => {
        getModelCv();
        document.title = 'RIMTIC' + ' - ' + t('CVs')

    }, []);

    const getModelCv = () => {
        setOpenW(true);

        axios.get('/api/modelesCV', {
            headers: {
                "Accept": "application/json"
            }
        }).then(response => {
            setModelesCv(response.data.modelesCVs);  
            setOpenW(false);
        }).catch(error => {
            console.log(error);
            setOpenW(false);
        });

    };

    return (
        <Container style={{marginTop:'20px'}}>
            <li className="list-group-item active fontAlmari" style={{backgroundColor:'#013A05', borderColor:'#227b1c', fontWeight:'bold', fontSize:'20px', color:'#fff', padding:'15px' }} ><center>{t('modelCvs')}</center></li>
			<div className="row">
				{modelesCv.map((o) => (
					<div key={o.id} className="col-lg-3 col-sm-6" style={{paddingTop:'20px'}}>
						{o.libelle}<br/>
						<a href={'https://www.rimtic.com/storage/modeles/' + o.url } target="_blank"><img src={'https://www.rimtic.com/storage/modeles/' + o.icon} /></a>
					</div>					
				))}
			</div>
        </Container> 
    );
};

export default ModelesCV;