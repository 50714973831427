import React from 'react';
import { useTranslation } from 'react-i18next';
import { Container } from 'react-bootstrap';
import Accordion from 'react-bootstrap/Accordion';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faQuestionCircle, faCheckCircle } from "@fortawesome/free-solid-svg-icons";
import { Row, Col } from "react-bootstrap";

const ReussirVotreEntretien = () => {
    const { t, i18n } = useTranslation();
    return (
            <Container style={{marginTop:'20px'}}>
            <li className="list-group-item active fontAlmari" style={{backgroundColor:'#013A05', borderColor:'#227b1c', fontWeight:'bold', fontSize:'20px', color:'#fff', padding:'10px 15px 15px 15px' }} ><center>{t('reussir_votre_entretien')}</center></li>
			<div className="row">
				<Accordion defaultActiveKey="0">
                    
                    <Accordion.Item eventKey="3">
                        <Accordion.Header className='fontAlmari' style={{background:'#eee'}}><FontAwesomeIcon icon={faCheckCircle} style={{padding:'0px 7px 0px 7px'}}/>{t('reussir_votre_entretien')}</Accordion.Header>
                        <Accordion.Body> 
                           
                            <p className="fontAlmari" style={{color:'#013A05', fontSize:'20px'}}>{t('titre_5_conseils')}</p>
                            <br/>
                            <p className="fontAlmari" style={{color:'black', fontSize:'20px'}}>{t('voici_les_etapes_suivre')}</p>
                            <br/>
                            <ol>
                                <li style={{color:'red', fontSize:'20px'}}>
                                    <Row>
                                        <Col xs="12" lg="9">
                                            <p className="fontAlmari" style={{color:'red', fontSize:'20px'}}>{t('Faites_vos_recherches')}</p>
                                            <p className="fontAlmari" style={{color:'black', textAlign:'justify', fontSize:'15px'}}>{t('desc_Faites_vos_recherches')}</p>
                                            <br/> 
                                        </Col>
                                        <Col xs="12" lg="3">
                                            <img style={{marginTop:'30px', width:'100%', height:'70%'}} src='https://www.rimtic.com/storage/images/search.png' />
                                        </Col>
                                    </Row>    
                                </li>

                                <li style={{color:'red', fontSize:'20px'}}>
                                    <Row>
                                        <Col xs="12" lg="9">
                                            <p className="fontAlmari" style={{color:'red', fontSize:'20px'}}>{t('soyez_prépare')}</p>
                                            <p className="fontAlmari" style={{color:'black', textAlign:'justify', fontSize:'15px'}}>{t('desc_soyez_prépare')}</p>
                                            <br/> 
                                        </Col>
                                        <Col xs="12" lg="3">
                                            <img style={{marginTop:'30px', width:'100%', height:'70%'}} src="https://www.rimtic.com/storage/images/soyezpret.jpg" />
                                        </Col>
                                    </Row>  
                                    
                                </li>

                                <li style={{color:'red', fontSize:'20px'}}>
                                    <Row>
                                        <Col xs="12" lg="9">
                                            <p className="fontAlmari" style={{color:'red', fontSize:'20px'}}>{t('bonne_première_impression')}</p>
                                            <p className="fontAlmari" style={{color:'black', textAlign:'justify', fontSize:'15px'}}>{t('desc_bonne_première_impression')}</p>
                                            <br /> 
                                        </Col>
                                        <Col xs="12" lg="3">
                                            <img style={{marginTop:'30px', width:'100%', height:'70%'}} src="https://www.rimtic.com/storage/images/impression.jpg" />
                                        </Col>
                                    </Row>  
                                    
                                </li>

                                <li style={{color:'red', fontSize:'20px'}}>
                                    <Row>
                                        <Col xs="12" lg="9">
                                            <p className="fontAlmari" style={{color:'red', fontSize:'20px'}}>{t('Soyez_honnête')}</p>
                                            <p className="fontAlmari" style={{color:'black', textAlign:'justify', fontSize:'15px'}}>{t('desc_Soyez_honnête')}</p>
                                            <br /> 
                                        </Col>
                                        <Col xs="12" lg="3">
                                            <img style={{marginTop:'30px', width:'100%', height:'70%'}}  src="https://www.rimtic.com/storage/images/honetes.jpg" />
                                        </Col>
                                    </Row>  
                                    
                                </li>

                                <li style={{color:'red', fontSize:'20px'}}>
                                    <Row>
                                        <Col xs="12" lg="9">
                                            <p className="fontAlmari" style={{color:'red', fontSize:'20px'}}>{t('Posez_toujours')}</p>
                                            
                                            <p className="fontAlmari" style={{color:'black', textAlign:'justify', fontSize:'15px'}}>{t('desc_Posez_toujours')}</p>
                                            <br />
                                        </Col>
                                        <Col xs="12" lg="3">
                                            <img style={{marginTop:'30px', width:'100%', height:'70%'}} src="https://www.rimtic.com/storage/images/question.jpg" />
                                        </Col>
                                    </Row>  
                                    
                                </li>
                            </ol>
                                

                        </Accordion.Body>
                    </Accordion.Item>

                    <Accordion.Item eventKey="4">
                        <Accordion.Header className='fontAlmari' style={{background:'#eee'}}><FontAwesomeIcon icon={faQuestionCircle} style={{padding:'0px 7px 0px 7px'}}/>{t('question_embauche')}</Accordion.Header>
                        <Accordion.Body>

                            <p className="fontAlmari" style={{color:'#013A05', fontSize:'20px'}}>{t('titre_5_conseils')}</p>
                                       <br/> 
                                       <ol>
                                            <li style={{color:'red', fontSize:'20px'}}>
                                                <Row>
                                                    <Col xs="12" lg="9">
                                                        <p className="fontAlmari" style={{color:'red', fontSize:'20px'}}>{t('Parlez-moi-de-vous')}</p>
                                                        <p className="fontAlmari" style={{color:'black', textAlign:'justify', fontSize:'15px'}}>{t('desc_Parlez-moi-de-vous')}</p>
                                                        <br/> 
                                                    </Col>
                                                    <Col xs="12" lg="3">
                                                        <img style={{marginTop:'30px', width:'100%', height:'70%'}} src='https://www.rimtic.com/storage/images/sell-yourself-6-ways.jpg' />
                                                    </Col>
                                                </Row>    
                                            </li>

                                            <li style={{color:'red', fontSize:'20px'}}>
                                                <Row>
                                                    <Col xs="12" lg="9">
                                                        <p className="fontAlmari" style={{color:'red', fontSize:'20px'}}>{t('points_forts')}</p>
                                                        <p className="fontAlmari" style={{color:'black', textAlign:'justify', fontSize:'15px'}}>{t('desc_points_forts')}</p>
                                                        <br/> 
                                                    </Col>
                                                    <Col xs="12" lg="3">
                                                        <img style={{marginTop:'30px', width:'100%', height:'70%'}} src="https://www.rimtic.com/storage/images/forts.png" />
                                                    </Col>
                                                </Row>  
                                                
                                            </li>

                                            <li style={{color:'red', fontSize:'20px'}}>
                                                <Row>
                                                    <Col xs="12" lg="9">
                                                        <p className="fontAlmari" style={{color:'red', fontSize:'20px'}}>{t('points_faibles')}</p>
                                                        <p className="fontAlmari" style={{color:'black', textAlign:'justify', fontSize:'15px'}}>{t('desc_points_faibles')}</p>
                                                        <br /> 
                                                    </Col>
                                                    <Col xs="12" lg="3">
                                                        {/* <img style={{marginTop:'30px', width:'100%', height:'70%'}} src="https://www.rimtic.com/impression.jpg" /> */}
                                                    </Col>
                                                </Row>  
                                                
                                            </li>

                                            <li style={{color:'red', fontSize:'20px'}}>
                                                <Row>
                                                    <Col xs="12" lg="9">
                                                        <p className="fontAlmari" style={{color:'red', fontSize:'20px'}}>{t('devrions-nous-vous-engager')}</p>
                                                        <p className="fontAlmari" style={{color:'black', textAlign:'justify', fontSize:'15px'}}>{t('desc_devrions-nous-vous-engager')}</p>
                                                        <br /> 
                                                    </Col>
                                                    <Col xs="12" lg="3">
                                                        {/* <img style={{marginTop:'30px', width:'100%', height:'70%'}}  src="http://localhost:5000/images/honetes.jpg" /> */}
                                                    </Col>
                                                </Row>  
                                                
                                            </li>

                                            <li style={{color:'red', fontSize:'20px'}}>
                                                <Row>
                                                    <Col xs="12" lg="9">
                                                        <p className="fontAlmari" style={{color:'red', fontSize:'20px'}}>{t('vos_objectifs')}</p>
                                                        
                                                        <p className="fontAlmari" style={{color:'black', textAlign:'justify', fontSize:'15px'}}>{t('desc_vos_objectifs')}</p>
                                                        <br />
                                                    </Col>
                                                    <Col xs="12" lg="3">
                                                        {/* <img style={{marginTop:'30px', width:'100%', height:'70%'}} src="https://www.rimtic.com/question.jpg" /> */}
                                                    </Col>
                                                </Row>  
                                                
                                            </li>

                                            <li style={{color:'red', fontSize:'20px'}}>
                                                <Row>
                                                    <Col xs="12" lg="9">
                                                        <p className="fontAlmari" style={{color:'red', fontSize:'20px'}}>{t('voulez_vous_travailler_chez_nous')}</p>
                                                        
                                                        <p className="fontAlmari" style={{color:'black', textAlign:'justify', fontSize:'15px'}}>{t('desc_voulez_vous_travailler_chez_nous')}</p>
                                                        <br />
                                                    </Col>
                                                    <Col xs="12" lg="3">
                                                        {/* <img style={{marginTop:'30px', width:'100%', height:'70%'}} src="https://www.rimtic.com/question.jpg" /> */}
                                                    </Col>
                                                </Row>  
                                                
                                            </li>

                                            <li style={{color:'red', fontSize:'20px'}}>
                                                <Row>
                                                    <Col xs="12" lg="9">
                                                        <p className="fontAlmari" style={{color:'red', fontSize:'20px'}}>{t('trois_choses_positives')}</p>
                                                        
                                                        <p className="fontAlmari" style={{color:'black', textAlign:'justify', fontSize:'15px'}}>{t('desc_trois_choses_positives')}</p>
                                                        <br />
                                                    </Col>
                                                    <Col xs="12" lg="3">
                                                        {/* <img style={{marginTop:'30px', width:'100%', height:'70%'}} src="https://www.rimtic.com/question.jpg" /> */}
                                                    </Col>
                                                </Row>  
                                                
                                            </li>

                                            <li style={{color:'red', fontSize:'20px'}}>
                                                <Row>
                                                    <Col xs="12" lg="9">
                                                        <p className="fontAlmari" style={{color:'red', fontSize:'20px'}}>{t('vos_prétentions')}</p>
                                                        
                                                        <p className="fontAlmari" style={{color:'black', textAlign:'justify', fontSize:'15px'}}>{t('desc_vos_prétentions')}</p>
                                                        <br />
                                                    </Col>
                                                    <Col xs="12" lg="3">
                                                        {/* <img style={{marginTop:'30px', width:'100%', height:'70%'}} src="https://www.rimtic.com/question.jpg" /> */}
                                                    </Col>
                                                </Row>  
                                                
                                            </li>

                                            <li style={{color:'red', fontSize:'20px'}}>
                                                <Row>
                                                    <Col xs="12" lg="9">
                                                        <p className="fontAlmari" style={{color:'red', fontSize:'20px'}}>{t('vous_étiez_un_animal')}</p>
                                                        
                                                        <p className="fontAlmari" style={{color:'black', textAlign:'justify', fontSize:'15px'}}>{t('desc_vous_étiez_un_animal')}</p>
                                                        <br />
                                                    </Col>
                                                    <Col xs="12" lg="3">
                                                        {/* <img style={{marginTop:'30px', width:'100%', height:'70%'}} src="https://www.rimtic.com/question.jpg" /> */}
                                                    </Col>
                                                </Row>  
                                                
                                            </li>
                                        </ol>

                        </Accordion.Body>
                    </Accordion.Item>

                  
                
                </Accordion>
			</div>
        </Container> 

    );
};

export default ReussirVotreEntretien;