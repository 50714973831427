import React from 'react';
import { useTranslation } from 'react-i18next';
import Moment from 'moment';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";  
import { FacebookShareButton, WhatsappShareButton } from 'react-share';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { faFacebook, faWhatsapp } from "@fortawesome/free-brands-svg-icons" 


const Partager = (props) => {
    const { t, i18n } = useTranslation();
    return (
            <Col xs="12" lg="12" >
                <div className='div-partager' style={{ border:'1px solid black', borderRadius:'5px'}}> 
                    <Row style={{padding:'4px'}}>
                        <Col xs="5" lg="6">
                            <span className='fontAlmari' style={{fontWeight:'bold'}}>{t('patatger_annonce')} :
                                <FacebookShareButton url={props.url}><FontAwesomeIcon icon={faFacebook} style={{padding:'0px 3px 0px 3px', color:'#498bc4', fontSize:'20px'}}/></FacebookShareButton> 
                                <WhatsappShareButton url={props.url}><FontAwesomeIcon icon={faWhatsapp} style={{padding:'0px 3px 0px 3px', color:'#25D366', fontSize:'20px'}}/></WhatsappShareButton> 
                            </span>
                        </Col>

                        <Col xs="7" lg="6">
                            <span className='fontAlmari' style={{fontWeight:'bold'}}>{t('publie_le')} : </span> {Moment(props.created_at).format('DD/MM/YYYY')}
                        </Col> 
                    </Row>
                </div>
            </Col> 
    );
};

export default Partager;