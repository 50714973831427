import React from 'react';
import axios from '../../lib/axios';
import { useParams, useNavigate } from 'react-router-dom';
import { useState, useEffect } from "react";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Moment from 'moment';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from 'react-router-dom';
import Table from 'react-bootstrap/Table';
import EnteteAdmin from '../../components/componentAdmin/EnteteAdmin'
import Swal from 'sweetalert2';  
import { useTranslation } from 'react-i18next';



const AdminAfficherDetailOffreEmploi = () => {
    const { t, i18n } = useTranslation();
    const [offreEmploi, setOffreEmploi] = useState({});
    const [societe, setSociete] = useState({});
    const [pieceJointes, setPieceJointes] = useState([]);
    const [type, setType] = useState('emploi'); 
    const {id} = useParams(); 
    const navigate = useNavigate();
    const ud = JSON.parse(window.sessionStorage.getItem('userData'));

    

    useEffect(() => {
        if(ud != null && ud.user.role_id ==1 ){ 
            getOffreEmploi(); 
        }
        else{
            navigate('/login')
        }
    }, []);

    const getOffreEmploi = () => {

        axios.get('/api/tic/demploie/'+ id, {
            headers: {
                "Accept": "application/json"
            }
        }).then(response => {
            setOffreEmploi(response.data.offeremp); 
            // setIdOffreEmploi(response.data.offeremp.id); 
            setSociete(response.data.offeremp.societe); 
            setPieceJointes(response.data.pieceJointes); 
            console.log(response.data)
        }).catch(error => {
            console.log(error);
        });

    };

    var detailAvis  = {}

    i18n.language == 'fr' ? 
        detailAvis = {
        'id' : offreEmploi.id, 
        'libelle' : offreEmploi.libelle, 
        'contenue' : offreEmploi.contenue, 
        'adresse' : offreEmploi.ville, 
        } 
        :  
        detailAvis = {
        'id' : offreEmploi.id,
        'libelle' : offreEmploi.libelle_ar, 
        'contenue' : offreEmploi.contenue_ar,
        'adresse' : offreEmploi.ville_ar,
        }

    const validerOffreEpmoi = () => { 
        Swal.fire({
            title: t('validation_offre_emp'),
            showDenyButton: true, 
            confirmButtonText: t('oui'),
            denyButtonText: t('non'),
            customClass: {
                actions: 'my-actions',
                cancelButton: 'order-1 right-gap',
                confirmButton: 'order-2',
                denyButton: 'order-3',
            }
            }).then((result) => {
            if (result.isConfirmed) {

                axios.post(`/api/validerOffreEmploi/${id}`, {
                headers: {
                    'Accept': 'application/json', 
                }
                }).then(response => { 
                console.log(response.data)
                Swal.fire(response.data.validation, '', 'success')   
                
                }).catch(error => {
                    console.log(error)    
                });

                
            } else if (result.isDenied) {
                Swal.fire(t('Validation_annuler'), '', 'info',)
            }
        })  
     

    }; 

    const desactiverOffreEpmoi = () => { 
        Swal.fire({
            title: t('desactiver_offre_emp'),
            showDenyButton: true, 
            confirmButtonText: t('oui'),
            denyButtonText: t('non'),
            customClass: {
                actions: 'my-actions',
                cancelButton: 'order-1 right-gap',
                confirmButton: 'order-2',
                denyButton: 'order-3',
            }
            }).then((result) => {
            if (result.isConfirmed) {

                axios.post(`/api/desactiverOffreEpmoi/${id}`, {
                headers: {
                    'Accept': 'application/json', 
                }
                }).then(response => { 
                console.log(response.data)
                Swal.fire(response.data.validation, '', 'success')
                //   Swal.fire({icon: "success", text: response.data.validation,})  
                
                }).catch(error => {
                    console.log(error)    
                });

                
            } else if (result.isDenied) {
                Swal.fire(t('Validation_annuler'), '', 'info')
            }
        })  
     

    }; 

    const annulerOffreEpmoi = () => { 
        Swal.fire({
            title: t('annuler_offre_emp'),
            showDenyButton: true, 
            confirmButtonText: t('oui'),
            denyButtonText: t('non'),
            customClass: {
                actions: 'my-actions',
                cancelButton: 'order-1 right-gap',
                confirmButton: 'order-2',
                denyButton: 'order-3',
            }
            }).then((result) => {
            if (result.isConfirmed) {

                axios.post(`api/tic/demploie/annuler/${id}/${type}`, {
                headers: {
                    'Accept': 'application/json', 
                }
                }).then(response => { 
                console.log(response.data)
                Swal.fire(response.data.validation, '', 'success')
                navigate('/tic')
                //   Swal.fire({icon: "success", text: response.data.validation,})  
                
                }).catch(error => {
                    console.log(error)    
                });

                
            } else if (result.isDenied) {
                Swal.fire(t('Validation_annuler'), '', 'info')
            }
        })  
     

    };

    const retournerOffreEpmoi = () => { 
        Swal.fire({
            title: t('retourner_offre_emp'),
            showDenyButton: true, 
            confirmButtonText: t('oui'),
            denyButtonText: t('non'),
            customClass: {
                actions: 'my-actions',
                cancelButton: 'order-1 right-gap',
                confirmButton: 'order-2',
                denyButton: 'order-3',
            }
            }).then((result) => {
            if (result.isConfirmed) {

                axios.post(`api/tic/demploie/retourne/${id}/${type}`, {
                headers: {
                    'Accept': 'application/json', 
                }
                }).then(response => { 
                console.log(response.data)
                Swal.fire(response.data.validation, '', 'success')
                navigate('/tic')
                //   Swal.fire({icon: "success", text: response.data.validation,})  
                
                }).catch(error => {
                    console.log(error)    
                });

                
            } else if (result.isDenied) {
                Swal.fire(t('Validation_annuler'), '', 'info')
            }
        })  
     

    };

    const mettreOffreEmploiAlaune = () => { 
        Swal.fire({
            title: 'metter cette offre a la une  ?',
            showDenyButton: true, 
            confirmButtonText: 'Yes',
            denyButtonText: 'No',
            customClass: {
                actions: 'my-actions',
                cancelButton: 'order-1 right-gap',
                confirmButton: 'order-2',
                denyButton: 'order-3',
            }
            }).then((result) => {
            if (result.isConfirmed) {

                axios.post(`api/tic/mettreOffreEmploiAlaune/${id}`, {
                headers: {
                    'Accept': 'application/json', 
                }
                }).then(response => { 
                console.log(response.data)
                Swal.fire(response.data.validation, '', 'success')   
                
                }).catch(error => {
                    console.log(error)    
                });

                
            } else if (result.isDenied) {
                Swal.fire('Validation annuler', '', 'info')
            }
        })  
     

    };

    const SupprimerOffreEmploiAlaune = () => { 
        Swal.fire({
            title: 'supprimer cet offre d\'emploi ala une ?',
            showDenyButton: true, 
            confirmButtonText: 'Yes',
            denyButtonText: 'No',
            customClass: {
                actions: 'my-actions',
                cancelButton: 'order-1 right-gap',
                confirmButton: 'order-2',
                denyButton: 'order-3',
            }
            }).then((result) => {
            if (result.isConfirmed) {

                axios.post(`api/tic/mettreOffreEmploiAlaune/${id}`, {
                headers: {
                    'Accept': 'application/json', 
                }
                }).then(response => { 
                console.log(response.data)
                Swal.fire(response.data.validation, '', 'success')
                //   Swal.fire({icon: "success", text: response.data.validation,})  
                
                }).catch(error => {
                    console.log(error)    
                });

                
            } else if (result.isDenied) {
                Swal.fire('Validation annuler', '', 'info')
            }
        })  
     

    };

    const mettreCandidatureEnligne = () => { 
        Swal.fire({
            title: 'mettre candidature de cet offre d\'emploi en ligne ?',
            showDenyButton: true, 
            confirmButtonText: 'Yes',
            denyButtonText: 'No',
            customClass: {
                actions: 'my-actions',
                cancelButton: 'order-1 right-gap',
                confirmButton: 'order-2',
                denyButton: 'order-3',
            }
            }).then((result) => {
            if (result.isConfirmed) {

                axios.post(`api/tic/demploie/candidatureEnLigne/${id}`, {
                headers: {
                    'Accept': 'application/json', 
                }
                }).then(response => {  
                Swal.fire(response.data.validation, '', 'success')   
                }).catch(error => {
                    console.log(error)    
                });

                
            } else if (result.isDenied) {
                Swal.fire('Validation annuler', '', 'info')
            }
        })  
     

    };

    const SupprimerCandidatureEnligne = () => { 
        Swal.fire({
            title: 'supprimer candidature de cet offre d\'emploi en ligne ?',
            showDenyButton: true, 
            confirmButtonText: 'Yes',
            denyButtonText: 'No',
            customClass: {
                actions: 'my-actions',
                cancelButton: 'order-1 right-gap',
                confirmButton: 'order-2',
                denyButton: 'order-3',
            }
            }).then((result) => {
            if (result.isConfirmed) {

                axios.post(`api/tic/demploie/candidatureEnLigne/${id}`, {
                headers: {
                    'Accept': 'application/json', 
                }
                }).then(response => {  
                Swal.fire(response.data.validation, '', 'success')
                //   Swal.fire({icon: "success", text: response.data.validation,})  
                
                }).catch(error => {
                    console.log(error)    
                });

                
            } else if (result.isDenied) {
                Swal.fire('Validation annuler', '', 'info')
            }
        })  
     

    };



    return (
        <>
            <EnteteAdmin></EnteteAdmin>  
            <div style={{padding:'20px'}}>  
                <Row>
                    <Col xs="12" lg="12">
                        <center>  
                            {societe.logo == null ? <img src="/images/offreRIMTIC.jpg" alt=""  style={{maxWidth:'300px'}}/> : 
                            <img className='image' src={'https://www.rimtic.com/storage/images/' + societe.logo} alt='Profile' style={{maxWidth:'300px'}} />
                            } 
                            <p style={{textAlign:'center',  fontWeight:'bold',marginTop:'1px' }}>{societe.nom}</p>
                        </center>
                    </Col>

                    <Col xs="12" lg="12" >
                        <p style={{textAlign:'center', fontSize:'20px',margin:'10px' }}>{detailAvis.libelle != 'null' ? detailAvis.libelle : null}</p>
                    </Col> 

                    <Col xs="12" lg="12">
                        <div className='info-soci-emp'  style={{paddingRight:'30px', padding:'2px'}}>
                            <p><FontAwesomeIcon icon="map-marker-alt" color="#255025" size="xs" /> {detailAvis.adresse != 'null' ? detailAvis.adresse : null}</p>
                            <p><FontAwesomeIcon icon="clock" color="#255025" size="xs" /> {offreEmploi.fin}</p>
                            <p><FontAwesomeIcon icon="eye" color="#255025" size="xs" /> {offreEmploi.counter}</p>
                        </div>
                    </Col> 

                    <Col xs="12" lg="12" >
                        <div style={{padding:'30px'}} dangerouslySetInnerHTML={{__html: detailAvis.contenue != '<p>null</p>' && detailAvis.contenue != 'null' ? detailAvis.contenue : null}} ></div>
                    </Col>

                    <Table striped bordered hover size="sm" style={{width:'80%', margin:'2px 0px 0px 40px'}}> 
                        <tbody>
                            {pieceJointes.map((o) => ( 
                            <tr>
                                <td><img src="https://www.rimtic.com/images/icons/docx.png" alt="" style={{width:'32px'}} /></td>
                                <td><a href={`https://www.rimtic.com/storage/documents/${o.nom}`} download target="_blank" style={{width:'98%'}}>{o.nom}</a></td> 
                            </tr> 
                            ))}
                            
                        </tbody>
                    </Table>

                    <Col xs="12" lg="12" >
                        <div style={{margin:'30px', padding:'7px', border:'1px solid black', borderRadius:'5px'}}> 
                            <Row style={{margin:'5px'}}>
                                <Col xs="12" lg="6">
                                    <span style={{fontWeight:'bold'}}>Partager l'annonce avec vos amis:</span>
                                </Col>
        
                                <Col xs="12" lg="6" style={{textAlign:'end'}}>
                                    <span style={{fontWeight:'bold'}}>Publié le : </span> {Moment(offreEmploi.created_at).format('DD MMM YYYY')}
                                </Col> 
                            </Row>
                        </div>
                    </Col> 
                </Row> 
            </div>

            <Row style={{paddingLeft:'40px', paddingRight:'40px'}}> 
            
                {offreEmploi.etat == 0 ? 
                <Col xs={12} sm={3} className='btn-deposer-in-accueil'><Button onClick={validerOffreEpmoi} style={{background:'green', color:'#fff'}}> Activer </Button> </Col>
                : 
                <Col xs={12} sm={3} className='btn-deposer-in-accueil'><Button onClick={desactiverOffreEpmoi} style={{background:'green', color:'#fff'}}> Desactiver </Button> </Col>
                }
                {offreEmploi.etat != 2 ?
                <Col xs={12} sm={3} className='btn-deposer-in-accueil'><Button onClick={annulerOffreEpmoi} style={{background:'red', color:'#fff'}}> Annuler </Button></Col>
                :
                offreEmploi.etat == 2 ?
                <Col xs={12} sm={3} className='btn-deposer-in-accueil'><Button onClick={retournerOffreEpmoi} style={{background:'#a19999', color:'#fff'}}> Retourner </Button></Col>
                :
                ''
                }
                {offreEmploi.alaune == 0 ?
                <Col xs={12} sm={3} className='btn-deposer-in-accueil'><Button onClick={mettreOffreEmploiAlaune} style={{background:'blue', color:'#fff'}}> Mettre a l'une  </Button></Col>
                : offreEmploi.alaune == 1 ?
                <Col xs={12} sm={3} className='btn-deposer-in-accueil'><Button style={{background:'blue', color:'#fff'}} onClick={SupprimerOffreEmploiAlaune}> Supprimer a l'une  </Button></Col>
                :''
                }

                {(offreEmploi.postule == 0) || (offreEmploi.postule == null) ?
                <Col xs={12} sm={3} className='btn-deposer-in-accueil'><Button onClick={mettreCandidatureEnligne} style={{background:'blue', color:'#fff'}}> Candidature en ligne  </Button></Col>
                : offreEmploi.postule == 1 ?
                <Col xs={12} sm={3} className='btn-deposer-in-accueil'><Button style={{background:'blue', color:'#fff'}} onClick={SupprimerCandidatureEnligne}> Annuler Candidature en ligne </Button></Col>
                :''
                }
                 
            </Row>  
        </>
    );
};

export default AdminAfficherDetailOffreEmploi;