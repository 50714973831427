import React, {useEffect, useState} from 'react'; 
import Container from 'react-bootstrap/Container';
import Table from 'react-bootstrap/Table'; 
import axios from '../../lib/axios';
import { Link } from 'react-router-dom'; 
 import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import Button from 'react-bootstrap/Button';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit } from "@fortawesome/free-solid-svg-icons";
 


const AccueilTraducteur = () => {
    const { t, i18n } = useTranslation();
    const [offresEmploi, setOffresEmploi] = useState([]);
    const [appelOffres, setAppelOffres] = useState([]);
    const [avis, setAvis] = useState([]);
    const navigate = useNavigate();
    const ud = JSON.parse(window.sessionStorage.getItem('userData'));

    useEffect(() => {
        if(ud != null && ud.user.role_id == 2 ){ 
            getOffres(); 
        }
        else{
            navigate('/login')
        } 
    }, []);

    const getOffres = () => {
        
        axios.get('/api/accueilTraducteur', {
            headers: {
                "Accept": "application/json"
            }
        }).then(response => { 
            setOffresEmploi(response.data.offresEmploi)
            setAppelOffres(response.data.appelsOffres)
            setAvis(response.data.autrePublications) 
        }).catch(error => {
            console.log(error);
        });

    };

    
     

    return (
        <>  
            <Container> 
                
                <br/>
                <Tabs className="tabs-in-accueil fontAlmari" id="controlled-tab-example" justify>
                    <Tab className="offresEmploi tab-inaccueil p-1 fontAlmari" eventKey="OffresEmploi" title={t("offre_emploi")} >
                            {offresEmploi.length != 0 ?
                            <Table striped bordered hover>
                                <thead>
                                    <tr> 
                                        <th className='fontAlmari'>Titre(Fr)</th>
                                        <th className='fontAlmari'>العنوان(Ar)</th>
                                        <th className='fontAlmari'>Actions</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {offresEmploi.map((o) => (
                                    <tr key={o.id}>  
                                        <td><Link to={`/offre-emploi/${o.slug}/${o.id}`}>{o.libelle}</Link></td>
                                        <td><Link to={`/offre-emploi/${o.slug}/${o.id}`}>{o.libelle_ar}</Link></td>
                                        
                                        <td>
                                            <Link to={`/traduction/offreEmploi/${o.id}`}>
                                                <Button>Traduire</Button>
                                            </Link>
                                        </td>
                                    </tr> 
                                    ))}
                                </tbody>
                            </Table>
                            : null }
                        
                    </Tab>

                    <Tab className="AppelsOffres tab-inaccueil p-1 fontAlmari" eventKey="AppelsOffres" title={t("apple_offre")} >
                        {appelOffres.length != 0 ?
                        <Table striped bordered hover>
                            <thead>
                                <tr> 
                                    <th className='fontAlmari'>Titre(Fr)</th>
                                    <th className='fontAlmari'>العنوان(Ar)</th> 
                                    <th>Actions</th>
                                </tr>
                            </thead>
                            <tbody>
                                {appelOffres.map((o) => (
                                <tr key={o.id}>  
                                    <td><Link to={`/appel-offres/${o.slug}/${o.id}`}>{o.libelle}</Link></td>
                                    <td><Link to={`/appel-offres/${o.slug}/${o.id}`}>{o.libelle_ar}</Link></td>
                                    <td>
                                        <Link to={`/traduction/appelOffre/${o.id}`}>
                                            <Button>Traduire</Button>
                                        </Link>
                                    </td>
                                </tr> 
                                ))}
                            </tbody>
                        </Table>
                        : null }
                    </Tab>

                    <Tab className="Pub tab-inaccueil p-1 fontAlmari" eventKey="Avis" title={t('avis')} >
                        {avis.length != 0?
                        <Table striped bordered hover>
                            <thead>
                                <tr> 
                                    <th className='fontAlmari'>{t('titre')}</th>
                                    <th className='fontAlmari'>العنوان(Ar)</th>
                                    <th>Actions</th>
                                </tr>
                            </thead>
                            <tbody>
                                {avis?.map((a) => (
                                <tr key={a.id}>  
                                    <td><Link to={`/avis/${a.slug}/${a.id}`}>{a.libelle}</Link></td>
                                    <td><Link to={`/avis/${a.slug}/${a.id}`}>{a.libelle_ar}</Link></td>
                                    <td> 
                                        <Link to={`/traduction/avis/${a.id}`}>
                                            <Button>Traduire</Button>
                                        </Link>
                                    </td>
                                </tr> 
                                ))}
                            </tbody>
                        </Table>
                        : null }
                    </Tab>
                </Tabs>
            </Container>
        </>

    );
};

export default AccueilTraducteur;