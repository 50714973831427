import React from 'react';
import { useParams } from 'react-router-dom'
import { useEffect, useState } from "react";
import axios from '../lib/axios';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlay, faClock } from '@fortawesome/free-solid-svg-icons'
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Moment from 'moment';  
import { useTranslation } from 'react-i18next';
import Spinner from 'react-bootstrap/Spinner';  
import { useNavigate } from 'react-router-dom';  


const AvisDetail = () => {
    const { slug, id } = useParams();
    const [avis, setAvis] = useState({});
    const [societe, setSociete] = useState({});
    const [pieceJointes, setPieceJointes] = useState([]);
    const { t, i18n } = useTranslation();
    const [openW, setOpenW] = useState(false);
    const navigate = useNavigate();


    useEffect(() => {
        let langDemande='fr';
        if (window.location.href.indexOf("/ar/") > -1) {
        langDemande='ar';
        } 

        if (i18n.language!=langDemande) {
        setLanguage();
        window.location.reload();
        }
        getAvisDetail();

    }, []);

    const setLanguage = () => {
    
    i18n.changeLanguage("fr"); 
    if (window.location.href.indexOf("/ar/") > -1) {
      i18n.changeLanguage("ar"); 
    } 
    
  }

    const getAvisDetail = () => {
        setOpenW(true);
        axios.get('/api/avis/' + slug + '/' + id, {
            headers: {
                "Accept": "application/json"
            }
        }).then(response => {

            if( response.data == ""  || response.data.data.AutrePubl.etat == 2){
                navigate('/')
            }else{ 
                setAvis(response.data.data.AutrePubl); 
                setPieceJointes(response.data.data.pieceJointes); 
                setSociete(response.data.data.AutrePubl.societe); 
                setOpenW(false);
            }

            

            i18n.language == 'fr' ? 
                document.title = t('avis') + ' - ' + response.data.data.AutrePubl.libelle + ' | ' + response.data.data.AutrePubl.societe.nom
            :   document.title = t('avis') + ' - ' + response.data.data.AutrePubl.libelle_ar + ' | ' + response.data.data.AutrePubl.societe.nom
 
        }).catch(error => {
            console.log(error);
        });
    };

    var detailAvis  = {}

    i18n.language == 'fr' ? 
        detailAvis = {
        'id' : avis.id, 
        'libelle' : avis.libelle, 
        'contenue' : avis.contenue, 
        } 
        :  
        detailAvis = {
        'id' : avis.id,
        'libelle' : avis.libelle_ar, 
        'contenue' : avis.contenue_ar,
        }
    return (
        <div style={{padding:'30px'}}> 
              
            {openW == true ? 
            <div className="text-center">
                <Spinner animation="border" variant="danger" />
            </div>
            :
            <Row> 
                <Col xs="12" lg="12">
                    <div className='info-soci-emp' style={{paddingRight:'60px', padding:'2px'}}> 
                        <p className='fontAlmari' style={{fontSize:'13px'}}><FontAwesomeIcon icon={faClock} color="#255025" size="xs" /> {t('limite')} : <span style={{color:'red'}}>{Moment(avis.fin).format('DD/MM/YYYY')}</span> </p> 
                    </div>
                </Col> 


                <Col xs="12" lg="12">
                    <center> 
                        {societe.logo == null ? <img src="/images/offreRIMTIC.jpg" alt=""  style={{maxWidth:'200px'}}/> : 
                            <img className='image' src={'https://www.rimtic.com/storage/images/' + societe.logo} alt='Profile' style={{maxWidth:'200px'}} />
                            }
                        <p className='fontAlmari' style={{textAlign:'center',  fontWeight:'bold',marginTop:'1px' }}>{societe.nom}</p>
                    </center>
                </Col>

                <Col xs="12" lg="12" >
                    <p className='fontAlmari' style={{textAlign:'center', fontSize:'20px',margin:'10px' }}>{detailAvis.libelle != 'null' ? detailAvis.libelle : null}</p>
                </Col> 

                

                <Col xs="12" lg="12" >
                    <div className="dangerouslySetInnerHTML" style={{padding:'15px 30px 15px 30px'}} dangerouslySetInnerHTML={{__html: detailAvis.contenue != '<p>null</p>' && detailAvis.contenue != 'null' ? detailAvis.contenue : null}} ></div>
                </Col>

                <Col xs="12" lg="12" >
                        {pieceJointes.map((o) => (
                        <Row style={{margin:'3px 10px 5px 10px', borderBottom :'1px solid rgb(210, 207, 207)', padding:'10px 10px 5px 10px'}}>    
                            <Col className="psj" xs="1" lg="1" style={{margin:'auto'}}> 
                                <img src={`https://www.rimtic.com/images/icons/${o.extension}.png`} alt="" style={{width:'32px'}} />
                            </Col> 
                            <Col className="psj" xs="11" lg="11" >
                                <a href={`https://www.rimtic.com/storage/documents/${o.nom}`} target="_blank" download style={{margin:'2px 7px 0px 7px', fontWeight:'400', color:'#227b1c'}}>{o.nom}</a>
                            </Col>
                        </Row>
                        ))}
                </Col>

                

                <Col xs="12" lg="12" style={{textAlign:'end', marginTop:'50px'}}>
                    <span className='fontAlmari' style={{fontWeight:'bold'}}> <FontAwesomeIcon icon={faPlay} /> {t('publie_le')} : </span> {Moment(avis.created_at).format('DD/MM/YYYY')}
                </Col> 
            </Row> 
            }

        </div>
    );
};

export default AvisDetail;