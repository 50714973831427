import React, {useState, useEffect} from 'react';
import { Container } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';  
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';   
import Table from 'react-bootstrap/Table'; 
import axios from '../../lib/axios';
import EnteteAdmin from '../../components/componentAdmin/EnteteAdmin'
import { useParams, Link, useNavigate } from 'react-router-dom'; 
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from 'react-i18next';
import { CKEditor } from 'ckeditor4-react';


const TraductionAvis = () => {
    const [avis, setAvis] = useState({});
    const [idAvis, setIdAvis] = useState(0); 
    const [adresse, setAdresse] = useState(null);
    const [libelle, setLibelle] = useState(null); 
    const [libelleAr, setLibelleAr] = useState(null);  
    const [contenue, setContenue] = useState(null);
    const [contenueAr, setContenueAr] = useState(null);
     
    const {id} = useParams();
    const navigate = useNavigate();
    const { t, i18n } = useTranslation(); 
    const ud = JSON.parse(window.sessionStorage.getItem('userData'));


    useEffect(() => { 
        if(ud != null && ud.user.role_id == 2 ){ 
            getAvis(); 
        }
        else{
            navigate('/login')
        } 
         
    }, []);

    

    const getAvis = () => { 
        axios.get(`/api/tic/davis/modifier/${id}`, {
            headers: {
                "Accept": "application/json"
            }
        }).then(response => { 
            setAvis(response.data.avis)
            setIdAvis(response.data.avis.id) 
            setLibelle(response.data.avis.libelle)
            setLibelleAr(response.data.avis.libelle_ar)
            setContenue(response.data.avis.contenue)
            setContenueAr(response.data.avis.contenue_ar) 
        }).catch(error => {
            console.log(error); 
        });

    };


    const modifierAvis = (e) => { 
        const form = new FormData(); 
        form.append('idAvis', idAvis);
        form.append('adresse', adresse);
        form.append('libelle', libelle); 
        form.append('libelle_ar', libelleAr);  
        if(contenue != null){ 
            form.append('contenue', contenue.printHeaderText);
        }
        if(contenueAr != null){ 
            form.append('contenue_ar', contenueAr.printHeaderTextAr);
        }  
        
        axios.post(`/api/traduireAvis`, form, {
            headers: {
                'Accept': 'application/json', 
            }
        }).then(response => {   
            navigate(`/trad`);
         }).catch(error => {
            console.log(error)    
        });
      
    }; 

   

    const changeContenueText = (value) => { 
        setContenue((prev)=>{
            return {
                ...prev,
                printHeaderText: value.editor.getData()
            }
        }) 
    }
    
    const changeContenueTextAr = (value) => { 
        setContenueAr((prev)=>{
            return {
                ...prev,
                printHeaderTextAr: value.editor.getData()
            }
        }) 
    }


    let editorToolbar = [
        { name: "tools", items: ["Maximize"] },
        {
            name: "clipboard",
            items: ["Cut", "Copy", "Paste", "PasteText", "-", "Undo", "Redo"]
        },
        { name: "links", items: ["Link", "Unlink"] },
        { name: "document", items: ["Source"] }, 
        {
            name: "basicstyles",
            items: [
            "Bold",
            "Italic",
            "Underline",
            "Strike",
            "-",
            "Subscript",
            "Superscript"
            ]
        },
        {
            name: "paragraph",
            items: [
            "NumberedList",
            "BulletedList",
            "-",
            "Outdent",
            "Indent",
            "Blockquote"
            ]
        },
        {
            name: "align",
            items: [
            "AlignLeft",
            "JustifyLeft",
            "JustifyCenter",
            "JustifyRight",
            "JustifyBlock"
            ]
        }, 
        { name: "styles", items: ["Format", "-", "Font", "-", "FontSize"] },
        { name: "colors", items: ["TextColor", "BGColor"] },
        { name: "insert", items: ["Image", "Table", "HorizontalRule"] },
        "/"
        
    ]

    return ( 
        <>
             <Container> 
                <div>
                    <p className='fontAlmari' style={{border:'solid 2px', borderLeft:'0px', borderRight:'0px', borderColor:'black', textAlign:'center', fontSize:'25px', marginTop:'10px', padding:'10px'}}>{t('trad_avis')}</p>
                </div> 

                <div>

                    <Row style={{margin:'10px 0px 5px 0px'}}>

                       <input type="text" hidden value={idAvis} />
                        <Col xs={12} sm={6}>
                            <Form.Label className='fontAlmari'>العنوان</Form.Label> 
                            <Form.Control type="text" value={libelleAr} onChange={(e) =>  setLibelleAr(e.target.value)}  /> 
                           
                        </Col>

                        <Col xs={12} sm={6}> 
                            <Form.Label className='fontAlmari'>Titre</Form.Label>
                            <Form.Control type="text" value={libelle} onChange={(e) =>  setLibelle(e.target.value)} disabled/> 
                            
                        </Col> 

                        
                        <Col xs={12} sm={12} style={{marginTop:'10px'}}> 
                            <Form.Label className='fontAlmari'>المحتوي</Form.Label>  
                            {
                                avis.contenue_ar && <CKEditor   
                                    config={{toolbar: editorToolbar,
                                    extraPlugins: "colorbutton,colordialog,font,justify",
                                    removeButtons: ""}} 
                                    initData={avis.contenue_ar} 
                                    onChange={changeContenueTextAr}
                                /> 

                            }
                            {
                                !avis.contenue_ar && <CKEditor   
                                    config={{toolbar: editorToolbar,
                                    extraPlugins: "colorbutton,colordialog,font,justify",
                                    removeButtons: ""}}   
                                    onChange={changeContenueTextAr}
                                /> 
                            }

                        </Col>  
                        
                        
                        

                    </Row>  

                    <div style={{textAlign:'center'}}> 
                        <Button className='btn btn-success fontAlmari' style={{width:'190px', background:'green', color:"#fff"}} onClick={modifierAvis}>{t('traduire')}</Button>
                    
                    </div>
                   

                </div>

            </Container> 
        </>

    );
};

export default TraductionAvis;