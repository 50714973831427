import React, { useState, useEffect } from "react";
import { Container } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Table from "react-bootstrap/Table";
import axios from "../lib/axios";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import { CKEditor } from "ckeditor4-react";
import { useTranslation } from "react-i18next";

const AvisPublier = () => {
  const [societes, setSocietes] = useState([]);
  const [societeSelected, setSocieteSelected] = useState({});
  const [filteredResults, setFilteredResults] = useState([]);
  const [searchInput, setSearchInput] = useState("");
  const [sosId, setSosId] = useState(0);
  const [nomSociete, setNomSociete] = useState("");
  const [logo, setLogo] = useState("");
  const [adresse, setAdresse] = useState("");
  const [libelle, setLibelle] = useState("");
  const [fin, setFin] = useState("");
  const [contenue, setContenue] = useState("");
  const [typeDocument, setTypeDocument] = useState("");
  const [piecejointe, setPiecejointe] = useState([]);
  const [validationInput, setValidationInput] = useState("");
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const [list, setList] = useState([0]);

  useEffect(() => {
    let langDemande = "fr";
    if (window.location.href.indexOf("/ar/") > -1) {
      langDemande = "ar";
    }

    if (i18n.language != langDemande) {
      setLanguage();
      window.location.reload();
    }
    getSocietes();
    document.title = "RIMTIC" + " - " + t("depose_avis");
  }, []);

  const setLanguage = () => {
    i18n.changeLanguage("fr");
    if (window.location.href.indexOf("/ar/") > -1) {
      i18n.changeLanguage("ar");
    }
  };

  const ajouterAuterFichier = () => {
    setList([...list, list.length]);
  };

  const removeFichier = (value, index) => {
    var arr = list.filter((item) => item !== value);
    var pj = piecejointe.filter((item, i) => i !== index);
    setList(arr);
    setPiecejointe(pj);
  };

  const getSocietes = () => {
    axios
      .get("/api/listSocietes", {
        headers: {
          Accept: "application/json",
        },
      })
      .then((response) => {
        setSocietes(response.data.societes);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const searchItems = (searchValue) => {
    setSearchInput(searchValue);
    if (searchInput !== "") {
      const filteredData = societes.filter((item) => {
        return Object.values(item)
          .join("")
          .toLowerCase()
          .includes(searchInput.toLowerCase());
      });
      setFilteredResults(filteredData);
    } else {
      setFilteredResults([]);
    }
  };

  const selectItems = (e) => {
    const id = e.target.value;
    const sos = societes.find((item) => item.id == id);
    setSocieteSelected(sos);
    setSosId(sos.id);
    setAdresse(sos.adresse);
  };

  const handleSaveAvis = (e) => {
    e.preventDefault();
    if (
      nomSociete.length != 0 &&
      typeDocument.length != 0 &&
      contenue.length != 0 &&
      libelle.length != 0 &&
      fin.length != 0
    ) {
      Swal.fire({
        title: t("attender"),
        showConfirmButton: false,
        onBeforeOpen: () => {
          Swal.showLoading();
        },
      });
      const form = new FormData();
      form.append("sosId", sosId);
      form.append("nomSociete", nomSociete);
      form.append("adresse", adresse);
      form.append("fin", fin);
      form.append("typeDocument", typeDocument);
      form.append("logo", logo);

      if (i18n.language == "fr") {
        form.append("libelle", libelle);
        form.append("contenue", contenue.printHeaderText);
      } else if (i18n.language == "ar") {
        form.append("libelle_ar", libelle);
        form.append("contenue_ar", contenue.printHeaderText);
      } else {
        console.log("");
      }

      piecejointe.forEach((element) => {
        form.append("piecejointe[]", element);
      });

      axios
        .post("/api/deposer_autre_publication", form, {
          headers: {
            Accept: "application/json",
          },
        })
        .then((response) => {
          if (i18n.language == "fr") {
            Swal.fire({
              icon: "success",
              text: "Votre offre a été envoyée avec succès!  Merci de nous contacter au +222 45 29 24 13 ou  +222 42 17 17 17 pour sa validation",
            }).then(function () {
              navigate("/");
            });
          } else {
            Swal.fire({
              icon: "success",
              text: "تم إرسال عرضك بنجاح! يرجى الاتصال بنا على 45292413 أو 42171717 للتحقق من الصحة",
              confirmButtonText: "حسنا",
            }).then(function () {
              navigate("/");
            });
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      setValidationInput(t("validation_champ"));
    }
  };

  const changeContenueText = (value) => {
    setContenue((prev) => {
      return {
        ...prev,
        printHeaderText: value.editor.getData(),
      };
    });
  };

  let editorToolbar = [
    { name: "tools", items: ["Maximize"] },
    {
      name: "clipboard",
      items: ["Cut", "Copy", "Paste", "PasteText", "-", "Undo", "Redo"],
    },
    { name: "links", items: ["Link", "Unlink"] },
    { name: "document", items: ["Source"] },
    {
      name: "basicstyles",
      items: [
        "Bold",
        "Italic",
        "Underline",
        "Strike",
        "-",
        "Subscript",
        "Superscript",
      ],
    },
    {
      name: "paragraph",
      items: [
        "NumberedList",
        "BulletedList",
        "-",
        "Outdent",
        "Indent",
        "Blockquote",
      ],
    },
    {
      name: "align",
      items: [
        "AlignLeft",
        "JustifyLeft",
        "JustifyCenter",
        "JustifyRight",
        "JustifyBlock",
      ],
    },
    { name: "styles", items: ["Format", "-", "Font", "-", "FontSize"] },
    { name: "colors", items: ["TextColor", "BGColor"] },
    { name: "insert", items: ["Image", "Table", "HorizontalRule"] },
    "/",
  ];

  return (
    <Container>
      <Button
        className="btn btn-dark btn-lg btn-block fontAlmari"
        disabled
        style={{ marginTop: "15px", marginBottom: "10px" }}
      >
        {t("msg_top_dans_publier_avis")}{" "}
        <span style={{ fontWeight: "bold" }}>contact@rimtic.com</span>
      </Button>

      <div>
        <p
          className="fontAlmari"
          style={{
            border: "solid 2px",
            borderLeft: "0px",
            borderRight: "0px",
            borderColor: "black",
            textAlign: "center",
            fontSize: "25px",
            padding: "5px",
          }}
        >
          {t("depose_avis")}
        </p>
      </div>

      <div>
        <Row
          style={{
            marginTop: "15px",
            background: "#EEE",
            margin: "15px 1px 0px 1px",
            paddingBottom: "10px",
          }}
        >
          <Col xs={12} sm={4}>
            <Form.Label className="fontAlmari">{t("societe")}</Form.Label>
            <input type="text" value={sosId} hidden />
            {Object.keys(societeSelected).length !== 0 ? (
              <>
                <Form.Control
                  type="text"
                  value={societeSelected.nom}
                  onChange={(e) => {
                    setSocieteSelected({});
                    searchItems(e.target.value);
                  }}
                />
              </>
            ) : (
              <>
                <Form.Control
                  type="text"
                  value={nomSociete}
                  onChange={(e) => {
                    searchItems(e.target.value);
                    setNomSociete(e.target.value);
                  }}
                />
                {searchInput.length > 2
                  ? filteredResults.map((item) => {
                      return (
                        <option
                          key={item.id}
                          value={item.id}
                          onClick={selectItems}
                        >
                          {item.nom}
                        </option>
                      );
                    })
                  : ""}
              </>
            )}
            {nomSociete.length != 0 ? (
              ""
            ) : (
              <p className="fontAlmari" style={{ fontSize: 12, color: "red" }}>
                {validationInput}
              </p>
            )}
          </Col>

          <Col xs={12} sm={4}>
            <Form.Label className="fontAlmari">{t("logo")}</Form.Label>
            {Object.keys(societeSelected).length !== 0 ? (
              <>
                <br />
                <img
                  src={
                    "https://rimtic.com/storage/images/" + societeSelected.logo
                  }
                  style={{ height: "40px", width: "70px" }}
                />
              </>
            ) : (
              <Form.Control
                type="file"
                onChange={(e) => setLogo(e.target.files[0])}
              />
            )}
          </Col>
        </Row>

        <Row style={{ margin: "10px 0px 5px 0px" }}>
          <Col xs={12} sm={6}>
            <Form.Label className="fontAlmari">{t("titre")}</Form.Label>
            <Form.Control
              type="text"
              value={libelle}
              onChange={(e) => setLibelle(e.target.value)}
            />
            {libelle.length != 0 ? (
              ""
            ) : (
              <p className="fontAlmari" style={{ fontSize: 12, color: "red" }}>
                {validationInput}
              </p>
            )}
          </Col>

          <Col xs={12} sm={3}>
            <Form.Label className="fontAlmari">{t("date_limite")}</Form.Label>
            <Form.Control
              type="date"
              value={fin}
              onChange={(e) => setFin(e.target.value)}
            />
            {fin.length != 0 ? (
              ""
            ) : (
              <p className="fontAlmari" style={{ fontSize: 12, color: "red" }}>
                {validationInput}
              </p>
            )}
          </Col>

          <Col xs={12} sm={3}>
            <Form.Label className="fontAlmari">{t("type_doc")}</Form.Label>
            <Form.Control
              type="text"
              value={typeDocument}
              onChange={(e) => setTypeDocument(e.target.value)}
            />
            {typeDocument.length != 0 ? (
              ""
            ) : (
              <p className="fontAlmari" style={{ fontSize: 12, color: "red" }}>
                {validationInput}
              </p>
            )}
          </Col>

          <Col xs={12} sm={12} style={{ marginTop: "10px" }}>
            <Form.Label className="fontAlmari">{t("description")}</Form.Label>

            <CKEditor
              config={{
                toolbar: editorToolbar,
                extraPlugins: "colorbutton,colordialog,font,justify",
                removeButtons: "",
              }}
              initData={contenue.printHeaderText}
              onChange={changeContenueText}
            />
            {contenue.length != 0 ? (
              ""
            ) : (
              <p className="fontAlmari" style={{ fontSize: 12, color: "red" }}>
                {validationInput}
              </p>
            )}
          </Col>
        </Row>

        <Table style={{ marginTop: "50px" }}>
          <tbody>
            {list.map((item, index) => {
              return (
                <tr key={item}>
                  <td style={{ width: "80%" }}>
                    <Form.Control
                      type="file"
                      onChange={(e) =>
                        setPiecejointe((arr) => [...arr, e.target.files[0]])
                      }
                    />{" "}
                  </td>

                  <td>
                    {list.length !== 1 && (
                      <Button
                        className="btn btn-success"
                        style={{
                          background: "none",
                          color: "red",
                          border: "0px",
                          width: "20%",
                        }}
                        onClick={() => removeFichier(item, index)}
                      >
                        X
                      </Button>
                    )}
                    {list.length - 1 === index && (
                      <Button
                        className="btn btn-success fontAlmari"
                        style={{
                          background: "none",
                          color: "black",
                          width: "80%",
                        }}
                        onClick={ajouterAuterFichier}
                      >
                        {" "}
                        {t("autre_fichier")}
                      </Button>
                    )}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </Table>

        <div style={{ textAlign: "center" }}>
          <Button
            className="btn btn-success fontAlmari"
            style={{ width: "190px", background: "green", color: "#fff" }}
            onClick={handleSaveAvis}
          >
            {t("valider")}
          </Button>
        </div>
      </div>
    </Container>
  );
};

export default AvisPublier;
