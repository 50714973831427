import React from "react";
import { useTranslation } from "react-i18next";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Moment from "moment";

const ListCvsDansAccueil = (props) => {
  const { t, i18n } = useTranslation();
  var today = new Date();
  var birthDate = new Date(props.date_naissance);
  var age_now = today.getFullYear() - birthDate.getFullYear();

  return (
    <>
      <Row className="mx-0 pt-1" style={{ margin: "1px" }}>
        {props.cvs.map((o, index) => (
          <Col
            key={index}
            xs="12"
            lg="4"
            className="pe-3"
            style={{ paddingBottom: "10px", fontSize: "13px" }}
          >
            <div className="contentcv">
              <div
                className="card"
                style={{
                  maxHeight: "170px",
                  paddingRight: "0px",
                  paddingLeft: "0px",
                }}
              >
                <div
                  className="card-body"
                  style={{ paddingTop: "0px", paddingBottom: "0px" }}
                >
                  <div className="firstinfo">
                    {o.photo == null || o.displayphoto == 0 ? (
                      <img
                        src="https://www.rimtic.com/candidat.jpg"
                        style={{ paddingTop: "0px" }}
                        alt="RIMTIC"
                        loading="lazy"
                      />
                    ) : (
                      <img
                        loading="lazy"
                        src={`https://www.rimtic.com/cvtheque-b/storage/images/${o.photo}`}
                        style={{ paddingTop: "0px" }}
                        alt={t(o.domain_d_etude)}
                      />
                    )}

                    <div className="profileinfo" style={{ width: "100%" }}>
                      <p
                        className="fontAlmari"
                        style={{
                          fontSize: "13px",
                          width: "100%",
                          color: "#000",
                        }}
                      >
                        {t(o.domain_d_etude)}
                      </p>
                      <h3 className="fontAlmari">{t(o.niveau)}</h3>
                    </div>
                  </div>
                </div>
                <div className="card-footer" style={{ paddingTop: "0px" }}>
                  <Row>
                    <Col xs="5" lg="5"></Col>
                    <Col xs="3" lg="3" style={{ textAlign: "center" }}>
                      <span className="fontAlmari" style={{ fontSize: "12px" }}>
                        {t("age")}
                      </span>
                    </Col>
                    <Col xs="4" lg="4" style={{ textAlign: "center" }}>
                      <span className="fontAlmari" style={{ fontSize: "12px" }}>
                        {t("experience")}
                      </span>
                    </Col>
                    <Col xs="5" lg="5"></Col>
                    <Col xs="3" lg="3" style={{ textAlign: "center" }}>
                      <span
                        className="fontAlmari"
                        style={{ fontSize: "18px", fontFamily: "bold" }}
                      >
                        {today.getFullYear() -
                          Moment(o.date_naissance).format("YYYY")}
                      </span>
                    </Col>
                    <Col xs="4" lg="4" style={{ textAlign: "center" }}>
                      <span
                        className="fontAlmari"
                        style={{ fontSize: "18px", fontFamily: "bold" }}
                      >
                        {Math.floor(
                          (o.a != null ? o.a : 0 + o.b != null ? o.b : 0) / 365
                        ) < 1
                          ? " < 1  "
                          : " > " +
                            Math.floor(
                              (o.a != null ? o.a : 0 + o.b != null ? o.b : 0) /
                                365
                            )}
                      </span>
                    </Col>
                  </Row>
                </div>
              </div>
            </div>
          </Col>
        ))}
      </Row>
    </>
  );
};

export default ListCvsDansAccueil;
