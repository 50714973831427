import React, {useEffect, useState} from 'react';
import EnteteAdmin from '../../components/componentAdmin/EnteteAdmin'
import Table from 'react-bootstrap/Table'; 
import { Link } from 'react-router-dom';
import Container from 'react-bootstrap/Container';
import axios from '../../lib/axios';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit } from '@fortawesome/free-solid-svg-icons'
import { Pagination } from '@mui/material/';
import Stack from '@mui/material/Stack'; 

const AfficherAppelsOffres = () => {
    const [appelsOffres, setAppelsOffres] = useState([]); 
    const [pageNumber, setPageNumber] = useState(1);
    const [pagesNumber, setPagesNumber] = useState(1);   
    const [cherche, setCherche] = useState("");  

    useEffect(() => {
        getAppelsOffres(1);
    }, []);

    const paginate = (event, value) => {
        setPageNumber(value);
        getAppelsOffres(value);
    };

    const getAppelsOffres = (pn) => {

        axios.get('/api/tic/afficherAppelsOffres?page='+pn, {
            headers: {
                "Accept": "application/json"
            }
        }).then(response => { 
            setPagesNumber(response.data.appelsOffres.last_page)
            setAppelsOffres(response.data.appelsOffres.data)  
        }).catch(error => {
            console.log(error);
        });

    };


    return (
            <>
                <EnteteAdmin></EnteteAdmin> 
                <Container> 
                    <br/>
                    <div>
                        <input type="text" onChange={(e) => setCherche(e.target.value)} />                       
                    </div>
                    <br/>
                    <Table striped bordered hover>
                        <thead>
                            <tr> 
                                <th>#</th>
                                <th>Libelle</th>
                                <th>Societe</th>
                                <th>Date debut</th>
                                <th>Date fin</th>
                                <th>Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            {appelsOffres.filter(offre=>offre.libelle.toLowerCase().includes(cherche)).map((o) => (
                            <tr> 
                                <td>{o.id}</td>
                                <td><Link to={`/tic/doffre/${o.id}`}>{o.libelle}</Link> </td>
                                <td>{o.societe.nom}</td>
                                <td> {o.debut} </td>
                                <td> {o.fin} </td>
                                <td><Link to={`/tic/doffre/modifier/${o.id}`}><FontAwesomeIcon style={{color:'blue'}} icon={faEdit}/></Link></td>
                            </tr> 
                             ))} 
                        </tbody>
                    </Table>
                    <Stack alignItems="center" sx={{p:3}}>
                        <Pagination hideNextButton={true} hidePrevButton={true} sx={{ background:'#fff'}} count={pagesNumber} page={pageNumber} onChange={paginate} variant="outlined" color="primary" shape="rounded" />
                    </Stack> 
                </Container>
            </>

    );
};

export default AfficherAppelsOffres;