import React, { useEffect, useState } from 'react';
import EnteteAdmin from '../../components/componentAdmin/EnteteAdmin'
import { useParams } from 'react-router-dom'
import axios from '../../lib/axios';
import Table from 'react-bootstrap/Table'; 
import { Link } from 'react-router-dom';
import Modal from 'react-bootstrap/Modal';

import Container from 'react-bootstrap/Container';
import { Button, Form } from 'react-bootstrap';

const AfficherDetailSocietes = () => {
    const { id } = useParams();
    const [societe, setSociete] = useState({});
    const [offresEmploi, setOffresEmploi] = useState([]);
    const [applesOffres, setApplesOffres] = useState([]);
    const [users, setUsers] = useState([]);

    const [show, setShow] = useState(false);
    
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    
    const [idSos, setIdSos] = useState('');
    const [nom, setNom] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [confirPassword, setConfirPassword] = useState('');
    const [confirMessgErr, setConfirMessgErr] = useState('');

    useEffect(() => {
        getAvisDetail();
    }, []);

    const getAvisDetail = () => {
        axios.get('/api/tic/desociete/'+ id, {
            headers: {
                "Accept": "application/json"
            }
        }).then(response => { 
            setSociete(response.data.societes)
            setNom(response.data.societes.nom)
            setIdSos(response.data.societes.id)
            setOffresEmploi(response.data.offres)
            setApplesOffres(response.data.appelsOffres) 
            setUsers(response.data.users) 
        }).catch(error => {
            console.log(error);
        });
    };

    const ajouterUser = (e) => {
        e.preventDefault(); 
        if(password === confirPassword){
            const form = {"nom" : nom, "email" : email, "password" : password, "idSos" : idSos}
            axios.post('/api/tic/ajouterUser/', form, {
                headers: {
                    "Accept": "application/json"
                }
            }).then(response => { 
                console.log(response.data)
                handleClose();
                getAvisDetail();
            }).catch(error => {
                console.log(error);
            });
        }else{
            setConfirMessgErr('password et confirm password different')
        }
    };
    return (
        <>
            <EnteteAdmin></EnteteAdmin>
            <br/>
            <Container>
                <p style={{fontSize:'20px', color:'black', fontWeight:'bold'}}>Societe</p>
                <Table striped bordered hover>
                        <thead>
                            <tr> 
                                <th>#</th>
                                <th>Nom</th>
                                <th>Logo</th>
                                <th>Adresse</th>  
                            </tr>
                        </thead>
                        <tbody> 
                            <tr> 
                                <td>{societe.id}</td>
                                <td>{societe.nom}</td>
                                <td> <img src={'https://www.rimtic.com//storage/images/'+societe.logo} alt="Empty" style={{width:'80px', height:'50px'}}/></td>
                                <td> {societe.adresse} </td> 
                            </tr> 
                        </tbody>
                </Table>  

                <p style={{fontSize:'20px', color:'black', fontWeight:'bold', marginBottom:'5px'}}>Les offers d'emploi</p>   
                <Table striped bordered hover>
                    <thead>
                        <tr> 
                            <th>#</th>
                            <th>Libelle</th>
                            <th>Societe</th>
                            <th>Date debut</th>
                            <th>Date fin</th>
                            <th>Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {offresEmploi.map((o) => (
                        <tr> 
                            <td>{o.id}</td>
                            <td><Link to={`/tic/demploie/${o.id}`}>{o.libelle}</Link> </td>
                            <td>{o.societe.nom}</td>
                            <td> {o.debut} </td>
                            <td> {o.fin} </td>
                            <td> <Link to={`/tic/demploie/modifier/${o.id}`}>Modifier</Link></td>
                        </tr> 
                            ))} 
                    </tbody>
                </Table> 

                <p style={{fontSize:'20px', color:'black', fontWeight:'bold', marginBottom:'5px'}}>Les apples d'offres</p>   
                <Table striped bordered hover>
                    <thead>
                        <tr> 
                            <th>#</th>
                            <th>Libelle</th>
                            <th>Societe</th>
                            <th>Date debut</th>
                            <th>Date fin</th>
                            <th>Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {applesOffres.map((o) => (
                        <tr> 
                            <td>{o.id}</td>
                            <td><Link to={`/tic/doffre/${o.id}`}>{o.libelle}</Link> </td>
                            <td>{o.societe.nom}</td>
                            <td> {o.debut} </td>
                            <td> {o.fin} </td>
                            <td><Link to={`/tic/doffre/modifier/${o.id}`}>Modifier</Link></td>
                        </tr> 
                            ))} 
                    </tbody>
                </Table>

                <p style={{fontSize:'20px', color:'black', fontWeight:'bold', marginBottom:'5px'}}>Users</p>   
                <Table striped bordered hover>
                    <thead>
                        <tr> 
                            <th>#</th>
                            <th>Nom</th>
                            <th>Email</th>
                        </tr>
                    </thead>
                    <tbody>
                        {users.map((u) => (
                        <tr> 
                            <td>{u.id}</td>
                            <td>{u.name}</td>
                            <td>{u.email}</td>
                        </tr> 
                            ))} 
                    </tbody>
                </Table>
                
                <div style={{textAlign:'center', marginTop:'20px'}}>
                    <Button onClick={handleShow} style={{width:'auto', background:'#2ab27b', color:'#fff'}}>Nouvel Utilisateur</Button>
                </div>
            </Container> 



            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                <Modal.Title>Nouvel Utilisateur</Modal.Title>
                </Modal.Header>
                <Form onSubmit={ajouterUser}>
                <Modal.Body>
                        <Form.Label>Nom</Form.Label>
                        <Form.Control type='text' value={nom} onChange={(e) =>setNom(e.target.value)}/>
                        <Form.Label>Email</Form.Label>
                        <Form.Control type='text' value={email} onChange={(e) => setEmail(e.target.value)}/>
                        <Form.Label>Password</Form.Label>
                        <Form.Control type='password' value={password} onChange={(e) => {setPassword(e.target.value); setConfirMessgErr('')}}/>
                        <Form.Label>Confirm Password</Form.Label>
                        <Form.Control type='password' value={confirPassword} onChange={(e) => {setConfirPassword(e.target.value); setConfirMessgErr('')}}/>
                        <p style={{color:'red', fontSize:'12px'}}>{confirMessgErr}</p>
                </Modal.Body>
                <Modal.Footer style={{textAlign:'center', marginTop:'20px'}}>
                    <Button variant="secondary" onClick={handleClose} style={{width:'auto', background:'#eee', color:'black'}}> Annuler </Button>
                    <Button variant="primary" type='submit' style={{width:'auto', background:'#2ab27b', color:'#fff'}}>
                        Ajouter
                    </Button>
                </Modal.Footer>
                </Form>
            </Modal>      
        </>

    );
};

export default AfficherDetailSocietes;