import React, {useEffect, useState} from 'react';
import EnteteAdmin from '../../components/componentAdmin/EnteteAdmin'
import Table from 'react-bootstrap/Table'; 
import { Link } from 'react-router-dom';
import Container from 'react-bootstrap/Container';
import axios from '../../lib/axios';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit } from '@fortawesome/free-solid-svg-icons'
import { Pagination } from '@mui/material/';
import Stack from '@mui/material/Stack'; 

const AfficherSocietes = () => {
    const [societes, setSocietes] = useState([]); 
    const [pageNumber, setPageNumber] = useState(1);
    const [pagesNumber, setPagesNumber] = useState(1);   
    const [cherche, setCherche] = useState("");  
    const [libelle, setLibelle] = useState("");  
    const [numberOffre, setNumberOffre] = useState(4);  

    useEffect(() => {
        getSocietes(1);
    }, []);

     const paginate = (event, value) => {
        setPageNumber(value);
        getSocietes(value);
    };

    const getSocietes = (pn) => {

        axios.get('/api/tic/afficherSocietes?page='+pn, {
            headers: {
                "Accept": "application/json"
            }
        }).then(response => { 
            setPagesNumber(response.data.societes.last_page)
            setSocietes(response.data.societes.data)  
        }).catch(error => {
            console.log(error);
        });

    };

    const chercherOffre = () => {
        const form = new FormData(); 
        form.append('libelle', libelle);
        form.append('numberOffre', numberOffre);

        axios.post('/api/chercherOffre', form, {
            headers: {
                "Accept": "application/json"
            }
        }).then(response => { 
            console.log(response.data);
            setSocietes(response.data); 
        }).catch(error => {
            console.log(error);
        });

    };

    
    return (
            <>
                <EnteteAdmin></EnteteAdmin> 
                <Container> 
                    {/* <br/>
                    <div> 
                        <input type="text" onChange={(e) => setCherche(e.target.value)} />  
                    </div> */}

                    <br/>
                    <div style={{display:'flex'}}> 
                        <input className='form-control' type="text" onChange={(e) => setLibelle(e.target.value)} />
                        <button className='btn btn-primary' onClick={chercherOffre} style={{width:'150px', background:'#0d6efd', color:'#fff', margin:'0px 10px 0px 10px'}}>chercher</button>
                    </div>


                    <br/>
                    <Table striped bordered hover>
                        <thead>
                            <tr> 
                                <th>#</th>
                                <th>Nom</th>
                                <th>Logo</th>
                                <th>Adresse</th> 
                                <th>Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            {societes.filter(sos=>sos.nom.toLowerCase().includes(cherche)).map((s) => (
                            <tr> 
                                <td>{s.id}</td>
                                <td><Link to={`/tic/desociete/${s.id}`}>{s.nom}</Link> </td>
                                <td> <img src={'https://www.rimtic.com/storage/images/'+s.logo} alt="Empty" style={{width:'80px', height:'50px'}}/></td>
                                <td> {s.adresse} </td> 
                                <td><Link to={`/tic/dsociete/modifier/${s.id}`}><FontAwesomeIcon style={{color:'blue'}} icon={faEdit}/></Link></td>
                            </tr> 
                             ))} 
                        </tbody>
                    </Table>
                    <Stack alignItems="center" sx={{p:3}}>
                        <Pagination hideNextButton={true} hidePrevButton={true} sx={{ background:'#fff'}} count={pagesNumber} page={pageNumber} onChange={paginate} variant="outlined" color="primary" shape="rounded" />
                    </Stack> 
                </Container>
            </>

    );
};

export default AfficherSocietes;