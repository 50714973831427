import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import { useNavigate } from "react-router-dom";
import axios from "../lib/axios";
import Swal from "sweetalert2";
import { useTranslation } from "react-i18next";

const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setpassword] = useState("");
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();

  const login = (e) => {
    e.preventDefault();
    axios.get("/sanctum/csrf-cookie").then((response) => {
      const payload = {
        email,
        password,
      };
      axios
        .post("/api/login", payload, {
          headers: {
            Accept: "application/json",
            "X-CSRFToken": `token.content`,
          },
        })
        .then((response) => {
          if (response.data.user) {
            const user = response.data.user;
            const token = response.data.token;
            window.sessionStorage.setItem(
              "userData",
              JSON.stringify({ signedIn: true, user, token })
            );
            const ud = JSON.parse(window.sessionStorage.getItem("userData"));
            if (ud.user.role_id == 1) {
              navigate("/tic");
            } else if (ud.user.role_id == 2) {
              navigate("/trad");
            } else {
              navigate("/");
            }
          }
        })
        .catch((response) => {
          Swal.fire({ icon: "error", text: "error.message" });
        });
    });
  };

  return (
    <Container>
      <br />
      <br />

      <Row
        style={{
          background: "#EEE",
          justifyContent: "center",
          padding: "10px",
        }}
      >
        <img
          src="https://www.rimtic.com/images/RIMTIC.png"
          alt=" "
          style={{
            border: "1px red solid",
            borderRadius: "50px",
            width: "100px",
            height: "100px",
            background: "#fff",
          }}
        />
      </Row>

      <Row style={{ border: "2px #EEE solid", padding: "30px" }}>
        <Col xs={12} sm={12} style={{ padding: "10px" }}>
          <Row>
            <Col xs={12} sm={2}>
              {" "}
              <Form.Label className="fontAlmari" style={{ fontWeight: "bold" }}>
                {t("email")}
              </Form.Label>
            </Col>
            <Col xs={12} sm={10}>
              <Form.Control
                className="fontAlmari"
                type="email"
                placeholder={t("email")}
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />{" "}
            </Col>
          </Row>
        </Col>

        <Col xs={12} sm={12} style={{ padding: "10px" }}>
          <Row>
            <Col xs={12} sm={2}>
              <Form.Label className="fontAlmari" style={{ fontWeight: "bold" }}>
                {t("password")}
              </Form.Label>
            </Col>
            <Col xs={12} sm={10}>
              <Form.Control
                className="fontAlmari"
                type="password"
                placeholder={t("password")}
                value={password}
                onChange={(e) => setpassword(e.target.value)}
              />
            </Col>
          </Row>
        </Col>

        <Col xs={12} sm={12} style={{ textAlign: "center" }}>
          <Button
            onClick={login}
            className="fontAlmari"
            style={{
              width: "150px",
              marginTop: "9px",
              background: "#3097d1",
              color: "#fff",
              fontWeight: "bold",
            }}
          >
            {t("login")}
          </Button>
        </Col>
      </Row>
    </Container>
  );
};

export default Login;
